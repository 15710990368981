import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";

const state = {
    patients: [],
    patient: null,
};


const getters = {
    getSinglePatient: (state) => state.patient,
};

const mutations = {
    setAllPatients(state, patients) {
        state.patients = patients.slice();
    },
    setSinglePatient(state, patient) {
        state.patient = patient;
    },
};

const actions = {


    async updatePatient({commit}, patient) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation updatePatient($id: ID!, $input: PatientInput!) {
                        updatePatient(id: $id, patientInput: $input) {
                           id
                        }
                    }
                `,
                variables: {
                    id: patient.id,
                    input: {
                        listOfFormSignId: patient.listOfFormSignId,
                        acceptPolicy: patient.acceptPolicy,
                        appointmentNotes: patient.appointmentNotes,
                        birthDate: patient.birthDate,
                        bookingEmailConfirm: patient.bookingEmailConfirm ? 1 : 0,
                        cancelEmailConfirm: patient.cancelEmailConfirm ? 1 : 0,
                        extraInfo: patient.extraInfo,
                        sex: patient.sex,
                        nationalId: patient.nationalId,
                        contact: {
                            firstName: patient.contact.firstName,
                            lastName: patient.contact.lastName,
                            occupation: patient.contact.occupation,
                            postCode: patient.contact.postCode,
                            state: patient.contact.state,

                            preferredName: patient.contact.preferredName,
                            //TODO: do the real values
                            // timeZone: patient.contact.timeZone,
                            timeZone: null,
                            address3: patient.contact.address3,
                            address2: patient.contact.address2,
                            address1: patient.contact.address1,
                            city: patient.contact.city,
                            //TODO: contactPhones -> backend only accept one value;
                            contactPhones: patient.contact.contactPhones.map((item)=>{
                                return {
                                    phone: item.phone,
                                    type: item.type
                                }
                            }),
                            email: patient.contact.email,
                            //TODO: do the real values
                            // countryId: patient.contact.countryId,
                            countryId: patient.countryId,
                            type: "USER"
                        },
                        referenceNumber: patient.referenceNumber,
                        medicareNumber: patient.medicareNumber,
                        emergencyContact: patient.emergencyContact
                    }

                    },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },

            });

            if(response.data.updatePatient){
               return true
            }else{
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    },
    async fetchAllPatients({commit}) {
        try {
            const response = await apolloClient.query({
                query: gql`
          query findAllPatient {
              findAllPatient {
                content {
                  id
                  nextAttendees {
                    booking {
                      bookingDate
                      appointmentTypeService {
                        name
                      }
                      id
                    }
                  }
                  previousAttendees {
                    booking {
                      bookingDate
                      appointmentTypeService {
                        name
                      }
                      id
                    }
                  }
                  sex
                  contact {
                    firstName
                    lastName
                    fullName
                  }
                }
              }
          }
        `,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setAllPatients', response.data.findAllPatient.content);
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }
    },
    async fetchSinglePatient({commit}, id) {
        try {
            const response = await apolloClient.query({
                query: gql`
          query findOnePatientById($id: ID!) {
            findOnePatientById(id: $id) {
                id
                contact {
                fullName
                  firstName
                  lastName
                  email
                  contactPhones {
                    phone
                  }
                }
                sex
                birthDate
            }
          }
        `,
                variables: {id: id},
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.errors) {
                alert('Failed to fetch patient:', response.data.errors)
                return;
            }
            console.log(id)

            commit('setSinglePatient', response.data.findOnePatientById);
        } catch (error) {
            console.error('Failed to fetch patient:', error);
        }
    },
    async addPatient({commit}, patient) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation createPatient($patientInput: PatientInput!) {
            createPatient(patientInput: $patientInput ) {
                id
                birthDate
                sex
                contact {
                  firstName
                  lastName
                  email
                }
            }
          }
        `,
                variables: {
                    patientInput: {
                        listOfFormSignId: patient.listOfFormSignId,
                        acceptPolicy: patient.acceptPolicy,
                        birthDate: patient.birthDate,
                        nationalId: patient.nationalId,
                        referenceNumber: patient.referenceNumber,
                        emergencyContact: patient.emergencyContact,
                        appointmentNotes: patient.appointmentNotes,
                        bookingEmailConfirm: patient.bookingEmailConfirm ? 1 : 0,
                        cancelEmailConfirm: patient.cancelEmailConfirm ? 1 : 0,
                        extraInfo: patient.extraInfo,
                        medicareNumber: patient.medicareNumber,
                        sex: patient.sex,
                        contact: {
                            firstName: patient.contact.firstName,
                            lastName: patient.contact.lastName,
                            occupation: patient.contact.occupation,
                            postCode: patient.contact.postCode,
                            preferredName: patient.contact.preferredName,
                            //TODO: do the real values
                            // timeZone: patient.contact.timeZone,
                            timeZone: null,
                            address3: patient.contact.address3,
                            address2: patient.contact.address2,
                            address1: patient.contact.address1,
                            city: patient.contact.city,
                            //TODO: contactPhones -> backend only accept one value;
                            contactPhones: patient.contact.contactPhones,
                            email: patient.contact.email,

                            //TODO: do the real values
                            // countryId: patient.contact.countryId,
                            countryId: patient.countryId,

                            type: "USER"
                        },
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.createPatient.errors) {

                console.error('Failed to create patient:', response.data.createPatient.errors);
                // alert(`Failed to create petient: ${response.data.createPatient.errors}`)
                return;
            }

        } catch (error) {
            console.error('Failed to create patient:', error.message);
        }
    },

};

export default {
    state,
    getters,
    mutations,
    actions,
};
