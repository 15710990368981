import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";

const state = {
    departmentsNote: [],
    departmentNote: null,
};

const getters = {
    getAllDepartmentsNote: (state) => state.departmentsNote,
    getSingleDepartmentNote: (state) => state.departmentNote,
};

const mutations = {


}

const actions = {


    async editDepartmentNote({commit}, form) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation editDepartmentNote($id: Int!, $input: DepartmentNoteInput!) {
                        editDepartmentNote(id: $id, departmentNoteInput: $input) {
                            id
                            
                        }
                    }
                `,
                variables: {
                    id: form.id,
                    input: {
                        name:form.name,
                        departmentId:form.departmentId,
                        formJsonQuestion:form.formJsonQuestion
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (error) {
            console.log(error.message);
            return false;
        }

        return true;
    },
    async createDepartmentNote({commit}, form) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createDepartmentNote($form: DepartmentNoteInput!) {
                        createDepartmentNote(departmentNoteInput: $form) {
                            id
                        }
                    }
                `,
                variables: {
                    form: form
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (e) {
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            return false;
        }

        return true;
    },
    async createPatientDepartmentNote({commit}, form) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createPatientDepartmentNote($form: PatientDepartmentNoteInput!) {
                        createPatientDepartmentNote(patientDepartmentNoteInput: $form) {
                            id
                        }
                    }
                `,
                variables: {
                    form: form
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (e) {

            return false;
        }



        return true;
    },
    async editPatientDepartmentNote({commit}, form) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation editPatientDepartmentNote($id: Int!,$form: PatientDepartmentNoteInput!) {
                        editPatientDepartmentNote(id: $id,patientDepartmentNoteInput: $form) {
                            id
                        }
                    }
                `,
                variables: {
                    id: form.id,
                    form: {
                        status: form.status,
                        formJsonAnswer: form.formJsonAnswer
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (e) {

            return false;
        }



        return true;
    }

}

export default {
    state,
    getters,
    mutations,
    actions,
};

