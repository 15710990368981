import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"#f7f7f7","light":"","hide-on-scroll":"","elevation":"0"}},[(_vm.responsive)?_c(VBtn,{staticClass:"default v-btn--simple",attrs:{"dark":"","color":"#10938F","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onClickBtn.apply(null, arguments)}}},[_c(VIcon,{attrs:{"color":"#10938F"}},[_vm._v("mdi-view-list")])],1):_vm._e(),_c(VToolbarTitle,{staticClass:"gray--text"},[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),_vm._t("default")],2),_c(VBreadcrumbs,{directives:[{name:"show",rawName:"v-show",value:(_vm.ShowBreadcrumbs),expression:"ShowBreadcrumbs"}],staticClass:"breadcrumb mb-9",attrs:{"items":_vm.breadcrumbs,"divider":">"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VBreadcrumbsItem,{attrs:{"to":item.to,"exact":item.exact}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }