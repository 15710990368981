<template>
  <v-app>
    <div v-if="isInSameDepartment()" class="d-flex justify-end d-print-none">
      <v-btn color="#ae1431"  dark @click="createNewPatientDepartmentNote">
        <v-icon left>mdi-plus</v-icon>
        Add
      </v-btn>
    </div>

    <v-card class="mt-5" v-for="patientDepartment in patientDepartments" :key="patientDepartment.id">
      <v-card-title :style="{'background-color': patientDepartment.status=='DRAFT' ? '#f1ef90' : '#8ab690'}">
        <v-container fluid>

          <div class="d-flex justify-space-between">
            <div>
              {{ patientDepartment.departmentNote.name }}
            </div>
            <div>
              {{ patientDepartment.status }}
            </div>
          </div>
        </v-container>

      </v-card-title>
      <v-card-text>
        <v-container fluid>

          <div class="d-flex justify-space-between">
            <div class="d-flex justify-start">
              <div>
                <v-icon class="mr-2">mdi-doctor</v-icon>

              </div>
              <div style="font-size: 18px">{{ patientDepartment.lastModifiedByObject?.contact?.fullName }}</div>
            </div>
            <div>
              {{`Last Modify Date  ${new Intl.DateTimeFormat('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).format(new Date(patientDepartment.lastModifiedDate))}`}}
            </div>
          </div>


        </v-container>

      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn text @click="showViewDetails(patientDepartment)" color="primary" class="text-body-2">View</v-btn>
        </div>
        <div v-if="patientDepartment.status=='PUBLISH'">
          <v-btn text v-if="$can('department_admin')" @click="navigateToEdit(patientDepartment)" color="primary" class="text-body-2">edit</v-btn>
        </div>
        <div v-else>
          <v-btn text v-if="isInSameDepartment()" @click="navigateToEdit(patientDepartment)" color="primary" class="text-body-2">edit</v-btn>

        </div>





      </v-card-actions>

    </v-card>


    <v-dialog
        max-width="800px"
    v-model="showPatientDepartmentDetailDialog"
    v-if="showPatientDepartmentDetailDialog"
    >
      <v-card>
        <v-card-title>
          {{selectedPatientDepartment?.departmentNote?.name}}
        </v-card-title>
        <v-card-text>
          <v-card
              class="mt-4"
              v-for="(questionGroup, index) in form"
              :key="index"
          >
            <v-card-title>{{ questionGroup.section }}</v-card-title>
            <v-card-text>
              <v-row
                  v-for="(question, index) in questionGroup.items"
                  :key="index"
                  class="d-flex align-center flex-wrap"
              >
                <v-col cols="12 mt-4">
                  <div v-if="question.type==='textArea'">
                    <v-textarea
                        readonly
                        :label="question.label"
                        v-model="question.value"
                        outlined
                        background-color="#f7f7f7"
                    >

                    </v-textarea>
                  </div>

                  <div v-else-if="question.type==='multiSelect'">
                    <label>{{question.label}}</label>
                    <v-radio-group v-model="question.value">
                      <v-radio
                          v-for="(option,index) in question.options"
                          :key="index"
                          :label="option"
                          :value="option"
                          readonly
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div v-else-if="question.type==='multiCheckBox'">
                    <v-container fluid>
                      <label>{{question.label}}</label>

                      <v-checkbox
                          dense
                          readonly
                          v-for="option in question.options"
                          v-model="question.optionsAnswers"
                          :label="option"
                          :value="option"
                      ></v-checkbox>
                    </v-container>

                  </div>
                  <div v-else>
                    <v-text-field
                        readonly
                        outlined
                        v-model="question.value"
                        :label="question.label"
                        :type="question.type"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";
import Header from "@/components/Header";
import _ from "lodash";

export default {
  components: {Header},
  name: "FormSignaturesView",
  data() {
    return {
      currentDepartmentId: this.$route.params.departmentId,
      myDepartmentIds:[],
      showPatientDepartmentDetailDialog:false,
      selectedPatientDepartment:null,
      form:[],
      patientDepartments: [],
      permissions: this.$store.getters.getPermissions,
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'total',
          value: 'total'
        }
      ]
    }
  },
  methods: {
    showViewDetails(patientDepartmentNote){

      this.selectedPatientDepartment = patientDepartmentNote
      const answers=this.selectedPatientDepartment.formJsonAnswer;
      const grouped = _.groupBy(answers, 'section');
      this.form = Object.keys(grouped).map(key => ({
        section: '' + key,
        items: grouped[key] || []  // Ensure items is always an array
      }));

      this.showPatientDepartmentDetailDialog = true


    },
    isInSameDepartment(){
      let result=false;

      for (let i = 0; i < this.myDepartmentIds.length; i++) {
        if(this.myDepartmentIds[i]==this.currentDepartmentId){
          result=true;
        }
      }
      return result

    },
    openDepartmentNoteDetails(department) {

      this.$router.push({path: `/patients/${this.$route.params.id}/departments/${department.id}/department-notes`});

    },
    isDoctorInSameDepartment(departmentId) {

      return this.myDepartmentsId.includes(departmentId);

    },
    createNewPatientDepartmentNote() {
     this.$router.push(`/patients/${this.$route.params.id}/departments/${this.$route.params.departmentId}/department-notes/create`)
    },

    navigateToEdit(patientDepartmentNoteId) {
      this.$router.push('/patients/' + this.$route.params.id + '/departments/' + this.$route.params.departmentId + '/department-notes/' + patientDepartmentNoteId.departmentNoteId + '/patient-department-notes/'+patientDepartmentNoteId.id+'/edit')
    },
  },
  mounted() {
    try {
      apolloClient.query({
        query: gql`
          query findAllPatientDepartmentNoteByDepartmentIdAndPatientId($departmentId: Int!,$patientId: Int!) {
            myDepartments{
            id
            }

            findAllPatientDepartmentNoteByDepartmentIdAndPatientId(departmentId:$departmentId,patientId:$patientId) {
             id
             status
             departmentNoteId
             formJsonAnswer{
             name
             value
             type
             section
             label
             options
             optionsAnswers
             }
             lastModifiedByObject{
             contact{
             fullName
             }
             }
             lastModifiedDate
             departmentNote{
             id
             name


             }
            }
          }
        `,
        variables: {
          departmentId: this.$route.params.departmentId,
          patientId: this.$route.params.id
        },
        context: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        },
      }).then(response => {
        this.patientDepartments = response.data.findAllPatientDepartmentNoteByDepartmentIdAndPatientId;
        this.myDepartmentIds = response.data.myDepartments.map(department => department.id);
      });

    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  },
  watch: {
    getPermissions: function (value) {
      this.permissions = value;
    },
  },
  computed: {
    ...mapGetters(['getPermissions'])
  },

}
</script>

<style scoped>
.v-card__title {
  background-color: #a8e3e1;
}

</style>
