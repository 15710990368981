import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('Header',{attrs:{"title":"Add Program"}}),_c(VForm,{ref:"form",attrs:{"lazy-validation":""}},[_c('div',[_c(VTextField,{staticClass:"text-right pa-4",staticStyle:{"max-width":"400px"},attrs:{"label":"Name","outlined":"","rules":[() => !!_vm.form.name || 'This field is required'],"error-messages":_vm.errorMessages,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',[_c(VTextField,{staticClass:"text-right pa-4",staticStyle:{"max-width":"400px"},attrs:{"label":"Name English","outlined":""},model:{value:(_vm.form.nameEn),callback:function ($$v) {_vm.$set(_vm.form, "nameEn", $$v)},expression:"form.nameEn"}})],1),_c('div',[_c(VTextarea,{staticClass:"text-right pa-4",staticStyle:{"max-width":"400px"},attrs:{"outlined":"","label":"description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('div',{staticStyle:{"max-width":"400px"}},[_c(VSelect,{attrs:{"outlined":"","label":"Status","items":['ACTIVE','DISABLE']},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c(VBtn,{staticClass:"mt-7",attrs:{"dark":"","color":"#ae1431","elevation":"0"},on:{"click":_vm.createForm}},[_vm._v("Add Fom Sign ")]),_c(VBtn,{staticClass:"mt-7 ml-3",attrs:{"outlined":"","elevation":"0","to":{ name: 'ViewProgram' }}},[_vm._v("Cancel ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }