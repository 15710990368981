import gql from 'graphql-tag';
import apolloClient from '../../apollo/apollo';

const state = {
    forms: []
};

const getters = {
    getAllFormSigns: (state) => state.forms,
};

const mutations = {
    setAllFormSigns(state, formSignsList) {
        state.forms = formSignsList.slice();
    }
};

const actions = {
    async editFormSign({commit}, payload) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation editFormSign($id:Int,$formSignInput: FormSignInput) {
              editFormSign(id:$id,formSignInput: $formSignInput) {
              id
            }
          }
        `, variables: {
                    id: payload.id,
                    formSignInput: payload.payload
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.editFormSign.errors) {
                console.error('Failed to edit form sign:', response.data.editFormSign.errors);
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error('Failed to edit  form sign:', error.message);
            return false;
        }
    },
    async createFormSign({commit}, payload) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation createFormSign($formSignInput: FormSignInput) {
              createFormSign(formSignInput: $formSignInput) {
              id
            }
          }
        `, variables: {
                    formSignInput: payload
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.createFormSign.errors) {
                console.error('Failed to create form sign:', response.data.createFormSign.errors);
                return false;
            } else {
                return true;
            }

            // commit('setSingleUser', response.data.createUser);
        } catch (error) {
            console.error('Failed to create  form sign:', error.message);
            return false;
        }
    },
    async editPatientFormSign({commit}, {forms, patientFormSignId}) {

        const myForm = [];
        forms.forEach(item => {
            item.items.forEach(x => {
                myForm.push(x);
            })
        })


        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation editPatientFormSign($patientFormSignInput: PatientFormSignInput) {
              editPatientFormSign(patientFormSignInput: $patientFormSignInput) {
              id
            }
          }
        `, variables: {
                    patientFormSignInput: {
                        patientFormSignId: patientFormSignId,
                        forms: myForm
                    }
                }, context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            if (response.data.editPatientFormSign.errors) {
                console.error('Failed to edit patient form sign:', response.data.editPatientFormSign.errors);
                return false;
            } else {
                return true;
            }

            // commit('setSingleUser', response.data.createUser);
        } catch (error) {
            console.error('Failed to create patient form sign:', error.message);
        }

    },

    hasOneOfRoles({commit}, roles) {
        return state.permissions.some(permission => roles.includes(permission));
    }, setPermissions({commit}, permissions) {
        commit('setPermissionsMutation', permissions);
    },

};

export default {
    state, getters, mutations, actions,
};
