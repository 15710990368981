<template>
  <v-app>
    <Header title="Form Sign">
      <v-btn dark :to="{ name: 'AddFormSign' }" color="#ae1431" elevation="0">
        <v-icon left> mdi-plus</v-icon>
        Add Form Sign
      </v-btn>
    </Header>

    <v-card class="mt-5">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Name</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="fomSign in formSigns" :key="fomSign.id" @click="navigateToEditFormSign(fomSign)" style="cursor: pointer">
            <td>{{ fomSign.name }} </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-app>
</template>

<script>
import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";
import Header from "@/components/Header.vue";

export default {
  components: {Header},
  data() {
    return {
      formSigns: [],
    }
  },
  methods: {
    async navigateToEditFormSign(role) {
      this.$router.push({name: 'EditFormSign', params: {id: role.id}});

    },
  },
  mounted() {
    try {
      apolloClient.query({
        query: gql`
          query findAllFormSign {
          findAllFormSign{
             id
             name
           }
          }
        `,
        context: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        },
      }).then(response => {
        this.formSigns = response.data.findAllFormSign;
      });

    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  }
};
</script>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  justify-content: space-between;
  width: -webkit-fill-available;
}

thead tr {
  background-color: #a8e3e1;
}
</style>
