import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";

const state = {
    treatmentNotes: [],
    treatmentNote: null,
    // treatmentNotes: [],
    // treatmentNote: null,
};

const getters = {
    getAllTreatmentNotes: (state) => state.treatmentNotes,
    getSingleTreatmentNote: (state) => state.treatmentNote,
};

const mutations = {


}

const actions = {


    async editTreatmentNote({commit}, form) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation editTreatmentNote($id: Int!, $input: TreatmentNoteInput!) {
                        editTreatmentNote(id: $id, treatmentNoteInput: $input) {
                            id
                            
                        }
                    }
                `,
                variables: {
                    id: form.id,
                    input: {
                        name:form.name,
                        departmentId:form.departmentId,
                        formJsonQuestion:form.formJsonQuestion
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (error) {
            console.log(error.message);
            return false;
        }

        return true;
    },
    async createTreatmentNote({commit}, form) {
        console.log(form)
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createTreatmentNote($form: TreatmentNoteInput!) {
                        createTreatmentNote(treatmentNoteInput: $form) { 
                            id
                        }
                    }
                `,
                variables: {
                    form: form
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (e) {
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            return false;
        }

        return true;
    },
    async createPatientTreatmentNote({commit}, form) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createPatientTreatmentNote($form: PatientTreatmentNoteInput!) {
                        createPatientTreatmentNote(patientTreatmentNoteInput: $form) {
                            id
                        }
                    }
                `,
                variables: {
                    form: form
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (e) {

            return false;
        }



        return true;
    },
    async editPatientTreatmentNote({commit}, payload) {

        console.log(payload);
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation editPatientTreatmentNote($id: Int!,$editPatientTreatmentNote: PatientTreatmentNoteInput!) {
                        editPatientTreatmentNote(id: $id,patientTreatmentNoteInput: $editPatientTreatmentNote) {
                            id
                        }
                    }
                `,
                variables: {
                    id: payload.id,
                    editPatientTreatmentNote: {
                        status: payload.status,
                        formJsonAnswer: payload.formJsonAnswer
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (e) {

            console.log(e.message)
            return false;
        }



        return true;
    }

}

export default {
    state,
    getters,
    mutations,
    actions,
};

