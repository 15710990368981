<template>
    <router-view />
</template>

<script>
  export default {
    name: 'NoNavbar',
  };
</script>

<style>
</style>