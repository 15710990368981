<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" >
          <v-card class="pa-4 pa-sm-6 pa-md-9">
            <v-form ref="form" lazy-validation>
              <v-select
              :items="departmentNotes"
              item-value="id"
              item-text="name"
              v-model="selectedDepartmentNote"
              label="Department Note Template"
              ></v-select>
              <v-card
                  class="mt-4"
                  v-if="selectedDepartmentNote"
                  v-for="(questionGroup, index) in form"
                  :key="index"
              >
                <v-card-title>{{ questionGroup.section }}</v-card-title>
                <v-card-text>
                  <v-row
                      v-for="(question, index) in questionGroup.items"
                      :key="index"
                      class="d-flex align-center flex-wrap"
                  >
                   <v-col cols="12 mt-4">
                    <div v-if="question.type==='textArea'">
                      <v-textarea
                      :label="question.label"
                      v-model="question.value"
                      outlined
                      background-color="#f7f7f7"
                      >

                      </v-textarea>
                    </div>
                     <div v-else-if="question.type==='multiSelect'">
                     <label>{{question.label}}</label>
                       <v-radio-group v-model="question.value">
                         <v-radio
                             v-for="(option,index) in question.options"
                             :key="index"
                             :label="option"
                             :value="option"
                         ></v-radio>
                       </v-radio-group>
                     </div>
                     <div v-else-if="question.type==='multiCheckBox'">
                       <v-container fluid>
                         <label>{{question.label}}</label>
                         <v-checkbox
                             dense
                             v-for="option in question.options"
                             v-model="question.optionsAnswers"
                             :label="option"
                             :value="option"
                         ></v-checkbox>
                       </v-container>

                     </div>
                     <div v-else>
                       <v-text-field
                           outlined
                           v-model="question.value"
                           :label="question.label"
                           :type="question.type"
                       ></v-text-field>
                     </div>
                   </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row class="mt-4">
                <v-col cols="12" sm="4">
                  <v-btn
                      @click="editPatientDepartmentNote('DRAFT')"
                      dark
                      color="#F8E08D"
                      elevation="0"
                      block
                      style="color: black"
                  >
                    save as draft
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                      @click="editPatientDepartmentNote('PUBLISH')"
                      dark
                      color="#ae1431"
                      elevation="0"
                      block
                  >
                    save and publish
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                      outlined
                      elevation="0"
                      @click="goback"
                      block
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import {mapState} from "vuex";
import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";
import Header from "@/components/Header.vue";
import _ from "lodash";

export default {
  data() {
    return {
      departmentNotes:[],
      selectedDepartmentNote:null,
      form:[],
      breadcrumb:
          'Update Patient Department Note'
    };
  }
  ,
  components: {
    Header
  },
  methods: {

    async editPatientDepartmentNote(status) {
      const questions=[];
     this.form.forEach(section => {
       section.items.forEach(question => {
         questions.push({
           name: question.name,
           type: question.type,
           label: question.label,
           section: question.section,
           value: question.value,
           options: question.options,
           optionsAnswers: question.optionsAnswers,
           description: question.description,
           listOfProgramId: question.listOfProgramId
         })
       })
      })

        const result = await this.$store.dispatch("editPatientDepartmentNote", {
          id: this.$route.params.patientDepartmentNoteId,
          formJsonAnswer: questions,
          status: status
        });
        if (result) {
          this.$router.back();
        } else {
          alert("Please fill out the required fields.");
        }
    }
    ,

    goback(){

      this.$router.back();
    },
    async fetchInitData() {

      try {
        const response = await apolloClient.query({
          query: gql`
          query findOnePatientDepartmentNoteById($id: Int!){


            findOnePatientDepartmentNoteById(id: $id){
            id
            departmentNoteId
            departmentNote{
            id
            name
            }
            status
            formJsonAnswer{
            name
            label
            value
            type
            section
            order
            options
            optionsAnswers
            description
            listOfProgramId
            }
            }

          }
        `,
          variables: {
            id: this.$route.params.patientDepartmentNoteId
          },
          context: {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
            },
          },
        });

        this.departmentNotes= [response.data.findOnePatientDepartmentNoteById.departmentNote];
        this.selectedDepartmentNote= response.data.findOnePatientDepartmentNoteById.departmentNote;

        const answers=response.data.findOnePatientDepartmentNoteById.formJsonAnswer;
        const grouped = _.groupBy(answers, 'section');
        this.form = Object.keys(grouped).map(key => ({
          section: '' + key,
          items: grouped[key] || []  // Ensure items is always an array
        }));



      } catch (error) {
        console.error('Failed to fetch department:', error.message);
      }
    }
  }
  ,

  mounted() {
    this.fetchInitData();
  }
  ,

  computed: {}
  ,


}
;
</script>


<style scoped>
.v-card__title {
  background-color: #a8e3e1;
}

@media (max-width: 600px) {
  .v-btn {
    margin-bottom: 12px;
  }
}
</style>
