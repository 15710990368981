import gql from "graphql-tag";
import apolloClient from "@/apollo/apollo";

const state = {
    appointmentTypeServices: [],
    appointmentTypeService: null,
};

const getters = {
    getAllAppointmentTypeServices: (state) => state.appointmentTypeServices,
    getSingleAppointmentTypeService: (state) => state.appointmentTypeService,
};

const mutations = {

    setAllAppointmentTypServices(state, appointmentTypeServices) {
        state.appointmentTypeServices = appointmentTypeServices;
    },
    setSingleAppointmentTypeService(state, appointmentTypeService) {
        state.appointmentTypeService = appointmentTypeService;
    },
}

const actions = {
    async setSingleAppointmentTypeService({commit}, payload) {
        commit('setSingleAppointmentTypeService', payload);
    },

    async updateAppointmentTypeService({commit}, {payload,id}) {



        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation updateAppointmentTypeService($id: Int!,$appointmentTypeServiceInput: AppointmentTypeServiceInput!) {
                       updateAppointmentTypeService(appointmentTypeServiceInput: $appointmentTypeServiceInput, id: $id) {
                           id
                           name
                           category
                           color
                           description
                           durationInMint
                           maxNumberPatient
                        }
                    }
                `,
                variables: {
                    appointmentTypeServiceInput: payload,
                    id: id
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },

            });
            commit('setSingleAppointmentTypeService', payload);
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }

    },
    async createAppointmentTypeService({commit}, payload) {



        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createAppointmentTypeService($appointmentTypeServiceInput: AppointmentTypeServiceInput!) {
                       createAppointmentTypeService(appointmentTypeServiceInput: $appointmentTypeServiceInput) {
                             id
                             name
                         }
                    }
                `,
                variables: {
                    appointmentTypeServiceInput: payload
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },

            });
            commit('setSingleAppointmentTypeService', payload);
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }

    },

    async fetchSingleAppointmentTypeService({commit}, id) {

        try {
            const response = await apolloClient.query({
                query: gql`
                    query findOneAppointmentTypeServiceById($id: ID!) {
                        findOneAppointmentTypeServiceById(id: $id) {
                            id
                            name
                            category
                            durationInMint
                        }
                    }
                `,
                variables: {
                    id: id
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                }
            });

            commit('setSingleAppointmentTypeService', response.data.findOneAppointmentTypeServiceById);
        } catch (error) {
            console.error("Failed to fetch users:", error);
        }

    }
}

export default {
    state,
    getters,
    mutations,
    actions,
};

