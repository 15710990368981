<template>
  <v-app>
    <Header title="Edit Form Sign"></Header>
    <v-form ref="form" lazy-validation>
      <!--      <div class="d-flex justify-start">-->
      <div>
        <v-text-field
            label="Name"
            style="max-width: 400px"
            class="text-right pa-4"
            v-model="form.name"
            :rules="[() => !!form.name || 'This field is required']"
            :error-messages="errorMessages"
            required
        ></v-text-field>
      </div>
      <div>
        <v-file-input
            @change="uploadImage($event)"
            v-model="form.pdfUrl"
            label="Pdf Raw"
            accept=".pdf"
            :loading="uploading"
        ></v-file-input>

        <div>{{ form.pdfUrl }}</div>
        <v-progress-linear
            v-if="uploading"
            :value="uploadProgress"
            height="25"
            striped
        >
          <strong>{{ Math.ceil(uploadProgress) }}%</strong>
        </v-progress-linear>
      </div>


      <v-file-input
          @change="uploadFormTemplates($event)"
          v-model="formTemplateFiles"
          label="Form Templates"
          multiple
          :loading="uploadingTemplates"
          class="mb-2"
      ></v-file-input>
      <v-list v-if="form.formTemplatePath.length > 0" dense>
        <v-list-item v-for="(path, index) in form.formTemplatePath" :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ path }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-progress-linear
          v-if="uploadingTemplates"
          :value="templateUploadProgress"
          height="25"
          striped
          class="mt-2"
      >
        <strong>{{ Math.ceil(templateUploadProgress) }}%</strong>
      </v-progress-linear>

      <div>
        <v-card elevation="0" outlined class="mb-4">
          <v-card-title>Questions</v-card-title>
          <v-card-text>
            <div
                v-for="(question, index) in form.formJsonQuestion"
                :key="index"
                class="d-flex align-center flex-wrap"
            >
              <v-text-field
                  v-model="question.label"
                  label="Label "
                  :error-messages="errorMessages"
                  required
              ></v-text-field>
              <v-text-field
                  v-model="question.name"
                  label="param name (for pdf)"
                  class="ma-2"
                  :error-messages="errorMessages"
                  required
              ></v-text-field>
              <v-select
                  class="ma-2"
                  label="type"
                  :items="['text','date','number','sign']" v-model="question.type">
              </v-select>
              <v-text-field
                  class="ma-2"
                  v-model="question.section"
                  label="section "
                  :error-messages="errorMessages"
                  required
              ></v-text-field>
              <v-btn icon @click="removeQuestion(index)" class="mb-3">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-btn
                class="text-body-2"
                @click="addQuestion"
                elevation="0"
                outlined
            >Add New Question
            </v-btn>
          </v-card-text>
        </v-card>

      </div>

      <div>
        <v-card elevation="0" outlined class="mb-4">
          <v-card-title>Images</v-card-title>
          <v-card-text>
            <div
                v-for="(image, index) in form.images"
                :key="index"
                class="d-flex align-center flex-wrap"
            >
              <v-text-field
                  v-model="image.name"
                  label="Image Name"
                  :error-messages="errorMessages"
                  required
              ></v-text-field>
              <v-file-input
                  @change="uploadImageField($event, index)"
                  label="Upload Image"
                  accept="image/*"
                  :loading="image.uploading"
                  class="ma-2"
              ></v-file-input>
              <v-img
                  max-height="150"
                  max-width="250"
                  :src="form.images[index].value"></v-img>
              <v-progress-linear
                  v-if="image.uploading"
                  :value="image.uploadProgress"
                  height="25"
                  striped
                  class="ma-2"
              >
                <strong>{{ Math.ceil(image.uploadProgress) }}%</strong>
              </v-progress-linear>
              <v-btn icon @click="removeImage(index)" class="mb-3">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
            <v-btn
                class="text-body-2"
                @click="addImage"
                elevation="0"
                outlined
            >Add New Image
            </v-btn>
          </v-card-text>
        </v-card>
      </div>


      <v-btn @click="editFormSign" class="mt-7" dark color="#ae1431" elevation="0"
      >Add Fom Sign
      </v-btn
      >

      <v-btn
          class="mt-7 ml-3"
          outlined
          elevation="0"
          :to="{ name: 'ViewFormSign' }"
      >Cancel
      </v-btn
      >
    </v-form>
  </v-app>
</template>

<script>
import {mapState} from "vuex";
import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";
import Header from "@/components/Header.vue";
import appointmentTypeService from "@/store/modules/appointmentTypeService";

export default {
  data() {
    return {
      form: {
        id:null,
        name: '',
        formJsonQuestion: [],
        formTemplatePath: [],
        images: []
        ,
      },
      errorMessages: "",
      uploading: false,
      uploadProgress: 0,
      fileInput: null,
      formTemplateFiles: [],
      uploadingTemplates: false,
      templateUploadProgress: 0,
    };
  },
  components: {Header},

  methods: {
    async uploadImage(event) {
      this.uploading = true;
      this.uploadProgress = 0;
      const formData = new FormData();
      formData.append("file", event);
      try {
        const response = await fetch(process.env.VUE_APP_API_URL + '/api/upload', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          body: formData,
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
          },
        });
        if (!response.ok) {
          throw new Error('Failed to upload the file.');
        }
        this.form.pdfUrl = await response.text();
      } catch (error) {
        console.log('error upload image: ' + error.message);
      } finally {
        this.uploading = false;
      }
    },
    async uploadFormTemplates(files) {
      if (!files || files.length === 0) {
        this.form.formTemplatePath = [];
        return;
      }

      this.uploadingTemplates = true;
      this.templateUploadProgress = 0;
      this.form.formTemplatePath = [];

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append("file", files[i]);

        try {
          const response = await fetch(process.env.VUE_APP_API_URL + '/api/upload', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            body: formData,
          });

          if (!response.ok) {
            throw new Error('Failed to upload the file.');
          }

          const filePath = await response.text();
          this.form.formTemplatePath.push(filePath);

          // Update progress
          this.templateUploadProgress = ((i + 1) / files.length) * 100;
        } catch (error) {
          console.log('Error uploading template: ' + error.message);
        }
      }
      this.uploadingTemplates = false;
    },
    addImage() {
      this.form.images.push({
        name: '',
        value: '',
        uploading: false,
        uploadProgress: 0
      });
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
    },
    async uploadImageField(event, index) {
      if (!event) {
        this.form.images[index].value = '';
        return;
      }

      this.form.images[index].uploading = true;
      this.form.images[index].uploadProgress = 0;
      const formData = new FormData();
      formData.append("file", event);

      try {
        const response = await fetch(process.env.VUE_APP_API_URL + '/api/upload', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          },
          body: formData,
          onUploadProgress: (progressEvent) => {
            this.form.images[index].uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
          },
        });
        if (!response.ok) {
          throw new Error('Failed to upload the image.');
        }

        this.form.images[index].value = await response.text();
      } catch (error) {
        console.log('Error uploading image: ' + error.message);
      } finally {
        this.form.images[index].uploading = false;
      }
    },
    addQuestion() {
      this.form.formJsonQuestion.push({
        name: '',
        type: 'text',
        label: '',
        section: ''
      })
    },
    removeQuestion(index) {
      this.form.formJsonQuestion.splice(index, 1);
    },
    async editFormSign() {

      const payload = {
        name: this.form.name,
        pdfUrl: this.form.pdfUrl,
        formJsonQuestion: this.form.formJsonQuestion,
        formTemplatePath: this.form.formTemplatePath,
        images: this.form.images.map(item => {
          return {name: item.name, value: item.value};
        })
      };
      const result = await this.$store.dispatch('editFormSign', {
        payload: payload,
        id:this.form.id
      })

      console.log(result);
      if (result === true) {
        this.$router.back();
      }
    }
  },
  mounted() {

    try {
      apolloClient.query({
        query: gql`
          query findOneFormSignById($id: Int!) {
            findOneFormSignById(id: $id){
              id
              name
              pdfUrl
              formJsonQuestion{
              name
              label
              type
              order
              section
              }
              formTemplatePath
              images{
              name
              value
              }
           }
          }
        `,
        variables :{
          id: this.$route.params.id
        },
        context: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        },
      }).then(response => {
        this.form = response.data.findOneFormSignById;


      });

    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  },
  computed: {},

};
</script>

<style scoped>
.v-card__title {
  background-color: #a8e3e1;
}
</style>
