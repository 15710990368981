import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";

const state = {
    departments: [],
    department: null,
};

const getters = {
    getAllDepartments: (state) => state.departments,
    getSingleDepartment: (state) => state.department,
};

const mutations = {

    setAllDepartments(state, departments) {
        state.departments = departments;
    },
    setSingleDepartment(state, department) {
        state.department = department;
    },
}

const actions = {
    async fetchDepartments({commit}) {
        try {
            const response = await apolloClient.query({
                query: gql`
                query findAllDepartments  {
                findAllDepartments {
                    id
                    name
                  }
                }
            `,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setAllDepartments', response.data.findAllDepartments);
        } catch (error) {
            console.log(error);
        }
    },
    async updateDepartment({commit}, form) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation updateDepartment($id: ID!, $input: DepartmentInput!) {
                        updateDepartment(id: $id, departmentInput: $input) {
                            id
                            name
                        }
                    }
                `,
                variables: {
                    id: form.id,
                    input: {
                        name: form.name,
                        displayName: form.displayName,
                        address1: form.address1,
                        address2: form.address2,
                        businessRegName: form.businessRegName,
                        businessRegNumber: form.businessRegNumber,
                        city: form.city,
                        contactInfo: form.contactInfo,
                        displayAddressOnlineBooking: +form.displayAddressOnlineBooking??null,
                        displayOnlineBooking: +form.displayOnlineBooking??null,
                        emailReplyTo: form.emailReplyTo,
                        logo: form.logo,
                        logoHeight: +form.logoHeight??null,
                        moreCommInfo: form.moreCommInfo,
                        moreInvoiceInfo: form.moreInvoiceInfo,
                        postCode: form.postCode,
                        sendAppointmentReminder: form.sendAppointmentReminder,
                        state: form.state,
                        websiteAddress: form.websiteAddress,
                        countryId: +form.countryId ??  null,
                        listOfAppointmentTypeServiceId: form.listOfAppointmentTypeServiceId
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (error) {
            console.log(error.message);
            return false;
        }

        return true;
    },
    async createDepartment({commit}, form) {

        console.log('form')
        console.log(form)
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createDepartment($form: DepartmentInput!) {
                        createDepartment(departmentInput: $form) {
                            id
                            name
                        }
                    }
                `,
                variables: {
                    form: {
                        name: form.name,
                        displayName: form.displayName,
                        address1: form.address1,
                        address2: form.address2,
                        businessRegName: form.businessRegName,
                        businessRegNumber: form.businessRegNumber,
                        city: form.city,
                        contactInfo: form.contactInfo,
                        displayAddressOnlineBooking: form.displayAddressOnlineBooking,
                        displayOnlineBooking: form.displayOnlineBooking,
                        emailReplyTo: form.emailReplyTo,
                        logo: form.logo,
                        logoHeight: form.logoHeight,
                        moreCommInfo: form.moreCommInfo,
                        moreInvoiceInfo: form.moreInvoiceInfo,
                        postCode: form.postCode,
                        sendAppointmentReminder: form.sendAppointmentReminder,
                        state: form.state,
                        websiteAddress: form.websiteAddress,
                        countryId: +form.countryId,
                        listOfAppointmentTypeServiceId: form.listOfAppointmentTypeServiceId
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });
        } catch (e) {
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            return false;
        }

        return true;
    }

}

export default {
    state,
    getters,
    mutations,
    actions,
};

