<template class="px-0 py-0">
  <v-app>
    <v-app-bar app color="#f7f7f7" light hide-on-scroll elevation="0">
      <v-btn
          v-if="responsive"
          class="default v-btn--simple"
          dark
          color="#10938F"
          icon
          @click.stop="onClickBtn"
      >
        <v-icon color="#10938F">mdi-view-list</v-icon>
      </v-btn>
      <v-toolbar-title class="gray--text">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <slot class="px-0"></slot>
    </v-app-bar>

    <v-breadcrumbs
        v-show="ShowBreadcrumbs"
        :items="breadcrumbs"
        divider=">"
        class="breadcrumb mb-9"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.to" :exact="item.exact">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-app>
</template>

<script>
export default {
  props: ["title"],
  data() {
    return {
      ShowBreadcrumbs: false,
    };
  },
  methods: {
    onClickBtn() {
      console.log('on click btn')
      this.$store.dispatch('toggleDrawerAction')
    },
  },
  computed: {
    responsive() {
      if (
          this.$vuetify.breakpoint.name == "xs" ||
          this.$vuetify.breakpoint.name == "sm" ||
          window.innerWidth <= 1069
      ) {
        return true;
      } else {
        return false;
      }
    },
    breadcrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift(); // remove the first empty element

      let breadcrumbArray = [];
      let fullPath = "";

      if (pathArray.length > 1) {
        this.ShowBreadcrumbs = true;
        pathArray.forEach((path, index) => {
          fullPath += `/${path}`;
          let route = this.$router.match(fullPath);
          if (route.meta && route.meta.breadcrumb) {
            breadcrumbArray.push({
              text: route.meta.breadcrumb,
              to: fullPath,
              exact: true,
            });
          }
        });

        breadcrumbArray[breadcrumbArray.length - 1].text = this.title;
      }
      return breadcrumbArray;
    }

    ,
  },
};
</script>

<style scoped>
.breadcrumb {
  margin: -36px -36px 15px -36px;
  background-color: aliceblue;
  padding: 10px 12px;
}

.v-app-bar{
  border-color: lightgray !important;
  border-bottom: 1px solid;
}
</style>
