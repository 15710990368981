import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import NoNavbar from '../layouts/NoNavbar.vue'
import WithNavbar from '../layouts/WithNavbar.vue'
import PatientDetailsList from '../layouts/PatientDetailsList.vue'
import AddPatientFormSign from "@/views/Patients/forms/EditPatientFormSign.vue";
import EditPatientFormSign from "@/views/Patients/forms/EditPatientFormSign.vue";
import FormSignView from "@/views/Settings/formSign/FormSignView.vue";
import AddFormSign from "@/views/Settings/formSign/AddFormSign.vue";
import EditFormSign from "@/views/Settings/formSign/EditFormSign.vue";
import EditPatientFormSignWithoutSidbar from "@/views/Patients/forms/EditPatientFormSignWithoutSidbar.vue";
import AddPatientDepartmentNote from "@/views/Patients/departmentNote/AddPatientDepartmentNote.vue";
import ListDepartmentNoteView from "@/views/Patients/departmentNote/ListDepartmentNoteView.vue";
import EditPatientDepartmentNote from "@/views/Patients/departmentNote/EditPatientDepartmentNote.vue";
import ProgramView from "@/views/Settings/Program/ProgramView.vue";
import EditProgram from "@/views/Settings/Program/EditProgram.vue";
import AddProgram from "@/views/Settings/Program/AddProgram.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/view/patients/:id/form-signatures/:patientFormSignId/edit',
        component: EditPatientFormSignWithoutSidbar,
        name: 'formSignaturesNewTap',
    },
    {
        path: '/',
        component: WithNavbar,
        children: [
            {
                path: '/',
                name: 'DashboardView',
                component: () => import('../views/DashboardView.vue'),
                meta: {breadcrumb: 'Dashboard'}
            },
            {
                path: '/appointements',
                name: 'AppointementsView',
                component: () => import('../views/AppointementsView.vue'),
                meta: {breadcrumb: 'Appointements'}
            },
            {
                path: '/statistics',
                name: 'StatisticsView',
                component: () => import('../views/StatisticsView.vue'),
                meta: {breadcrumb: 'Statistics'}
            },
            {
                path: '/patients',
                name: 'PatientView',
                component: () => import('@/views/Patients/PatientView.vue'),
                meta: {breadcrumb: 'Patients'}
            },
            {
                path: '/patients/new',
                name: 'AddPatient',
                component: () => import('@/views/Patients/AddPatient'),
                meta: {breadcrumb: 'New Patient'}
            },
            {
                path: '/patients/:id/edit',
                name: 'EditPatient',
                component: () => import('@/views/Patients/EditPatient.vue'),
                meta: {breadcrumb: 'Edit Patient'}
            },
            {
                path: '/settings/roles/:id/edit',
                name: 'EditRole',
                component: () => import('@/views/Settings/Roles/EditRole.vue'),
                meta: {breadcrumb: 'Edit Role'}
            },
            {
                path: '/settings/roles',
                name: 'ViewRoles',
                component: () => import('@/views/Settings/Roles/RolesView.vue'),
                meta: {breadcrumb: 'Roles'}
            },
            {
                path: '/settings/roles/new',
                name: 'AddRole',
                component: () => import('@/views/Settings/Roles/AddRole.vue'),
                meta: {breadcrumb: 'Add Role'}
            },
            {
                path: '/setting/my-profile',
                name: 'EditCurrentUserProfile',
                component: () => import('@/views/Settings/Users/EditCurrentUserProfile.vue'),
                meta: {breadcrumb: 'Edit Current User Profile'}
            },
            {
                path: '/patients/:id',
                name: 'PatientDetails',
                component: PatientDetailsList,
                props: true,
                meta: {breadcrumb: 'Patient details'},
                children: [
                    {
                        path: 'appointments',
                        name: 'PatientAppointments',
                        component: () => import('@/views/Patients/PatientAppointments.vue'),
                        meta: {breadcrumb: 'Patient Appointment'}
                    },
                    {
                        path: 'form-signatures',
                        name: 'formSignatures',
                        component: () => import('@/views/Patients/forms/FormSignaturesView.vue'),
                        meta: {breadcrumb: 'Patient Appointment'}
                    },
                    {
                        path: 'departments',
                        name: 'departmentNotes',
                        component: () => import('@/views/Patients/departmentNote/Departments.vue'),
                        meta: {breadcrumb: 'Patient Appointment'}
                    },
                    {
                        path: 'form-signatures/:patientFormSignId/edit',
                        name: 'formSignaturesCreate',
                        component: EditPatientFormSign,
                    },
                    {
                        path: 'departments/:departmentId/department-notes',
                        name: 'patientDepartmentNotesCreate',
                        component: ListDepartmentNoteView

                    },
                    {
                        path: 'departments/:departmentId/department-notes/create',
                        name: 'patientDepartmentNotesCreate',
                        component: AddPatientDepartmentNote
                    },
                    {
                        path: 'departments/:departmentId/department-notes/:departmentNoteId/patient-department-notes/:patientDepartmentNoteId/view',
                        name: 'patientDepartmentNotesView',
                    },
                    {
                        path: 'departments/:departmentId/department-notes/:departmentNoteId/patient-department-notes/:patientDepartmentNoteId/edit',
                        name: 'patientDepartmentNotesEdit',
                        component: EditPatientDepartmentNote

                    },
                    {
                        path: '',
                        component: () => import('@/views/Patients/PatientDetails'),
                        meta: {breadcrumb: 'Patient Details'}
                    },
                    {
                        path: 'treatment-notes',
                        name: 'treatmentNotes',
                        component: () => import('@/views/Patients/treatmentNote/ListTreatmentNoteView.vue'),
                        meta: {breadcrumb: 'Patient Treatment Notes'}
                    },
                    {
                        path: 'treatment-notes/create',
                        name: 'patientTreatmentNotesCreate',
                        component: () => import('@/views/Patients/treatmentNote/AddPatientTreatmentNote.vue'),
                    }, {
                        path: 'treatment-notes/:patientTreatmentNoteId/edit',
                        name: 'patientTreatmentNotesEdit',
                        component: () => import('@/views/Patients/treatmentNote/EditPatientTreatmentNote.vue'),
                    },
                ]
            },
            {
                path: '/settings',
                name: 'SettingsView',
                component: () => import('../views/SettingsView.vue'),
                meta: {breadcrumb: 'Settings'}
            },
            {
                path: '/settings/department-note',
                name: 'SettingsView',
                component: () => import('../views/Settings/DepartmentNote/DepartmentNoteView.vue'),
                meta: {breadcrumb: 'Department Note'}
            },
            {
                path: '/settings/departments/:departmentId/department-notes',
                name: 'ShowDepartmentNoteForDepartment',
                component: () => import('../views/Settings/DepartmentNote/DepartmentNoteInDepartmentView.vue'),
            },
            {
                path: '/settings/departments/:departmentId/department-notes/create',
                name: 'SettingsView',
                component: () => import('../views/Settings/DepartmentNote/AddDepartmentNote.vue'),
                meta: {breadcrumb: 'Settings'}
            },
            {
                path: '/settings/departments/:departmentId/department-notes/:departmentNoteId/edit',
                name: 'SettingsView',
                component: () => import('../views/Settings/DepartmentNote/EditDepartmentNote.vue'),
                meta: {breadcrumb: 'Settings'}
            },
            {
                path: '/settings/treatment-note',
                name: 'TreatmentNoteView',
                component: () => import('../views/Settings/TreatmentNote/TreatmentNoteInDepartmentView.vue'),
                meta: {breadcrumb: 'Department Note'}
            },
            {
                path: '/settings/treatment-notes/create',
                name: 'CreateTreatmentNoteView',
                component: () => import('../views/Settings/TreatmentNote/AddTreatmentNote.vue'),
                meta: {breadcrumb: 'Settings'}
            },
            {
                path: '/settings/treatment-notes/:treatmentNoteId/edit',
                name: 'SettingsView',
                component: () => import('../views/Settings/TreatmentNote/EditTreatmentNote.vue'),
                meta: {breadcrumb: 'Settings'}
            },
            {
                path: '/settings/users',
                name: 'UsersView',
                component: () => import('../views/Settings/Users/UsersView.vue'),
                meta: {breadcrumb: 'Users & practitioners'},
            },
            {
                path: '/settings/users/:id/edit',
                name: 'EditUser',
                component: () => import('../views/Settings/Users/EditUser.vue'),
                props: true,
                meta: {breadcrumb: 'Editing User'},
            },
            {
                path: '/settings/users/new',
                name: 'AddUser',
                component: () => import('../views/Settings/Users/AddUser.vue'),
                meta: {breadcrumb: 'New User'},
            },
            {
                path: '/settings/practitioners/:id/edit',
                name: 'EditPractitioner',
                component: () => import('@/views/Settings/Users/EditDoctorSetting.vue'),
                props: true,
                meta: {breadcrumb: 'Editing Practitioner'},
            },
            {
                path: '/settings/appointment-types',
                name: 'AppointmentTypeServiceView',
                component: () => import('@/views/Settings/AppointmentTypeService/AppointmentTypeServiceView.vue'),
                meta: {breadcrumb: 'Appointment Type'},
            },
            {
                path: '/settings/appointment-types/new',
                name: 'AddAppointmentTypeService',
                component: () => import('@/views/Settings/AppointmentTypeService/AddAppointmentTypeServiceView.vue'),
                meta: {breadcrumb: 'New Appointment Type'},
            },
            {
                path: '/settings/appointment-types/:id/edit',
                name: 'EditAppointmentTypeService',
                component: () => import('@/views/Settings/AppointmentTypeService/EditAppointmentTypeService.vue'),
                props: true,
                meta: {breadcrumb: 'Editing Appointment Type'},
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
            {
                path: '/settings/departments',
                name: 'DepartmentView',
                component: () => import('@/views/Settings/Department/DepartmentView.vue'),
                meta: {breadcrumb: 'Departments'},
            },
            {
                path: '/settings/departments/new',
                name: 'AddDepartment',
                component: () => import('@/views/Settings/Department/AddDepartment.vue'),
                meta: {breadcrumb: 'New Department'},
            },
            {
                path: '/settings/departments/:id/edit',
                name: 'EditDepartment',
                component: () => import('@/views/Settings/Department/EditDepartment.vue'),
                props: true,
                meta: {breadcrumb: 'Editing Department'},
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
            {
                path: '/settings/form-sign/add',
                name: 'AddFormSign',
                component: AddFormSign,
                props: true,
                meta: {breadcrumb: 'Add Form Sign'},
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
            {
                path: '/settings/form-sign/:id',
                name: 'EditFormSign',
                component: EditFormSign,
                props: true,
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
            {
                path: '/settings/form-sign',
                name: 'ViewFormSign',
                component: FormSignView,
                props: true,
                meta: {breadcrumb: 'Form Sign'},
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
            {
                path: '/settings/programs/add',
                name: 'AddProgram',
                component: AddProgram,
                props: true,
                meta: {breadcrumb: 'Add Form Sign'},
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
            {
                path: '/settings/programs/:id',
                name: 'EditProgram',
                component: EditProgram,
                props: true,
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
            {
                path: '/settings/programs',
                name: 'ViewPrograms',
                component: ProgramView,
                props: true,
                meta: {breadcrumb: 'Programs'},
                beforeEnter: async (to, from, next) => {
                    next();
                }
            },
        ],
    },
    {
        path: '/login',
        component: NoNavbar,
        children: [
            {
                path: '',
                name: 'Login',
                component: () => import('../views/Login.vue'),
            },
        ],
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored;
    const isAuthenticated = store.getters.isAuthenticated;
    if (to.name !== 'Login' && !isAuthenticated) next({name: 'Login'});
    else {
        next();
    }
});

export default router
