import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('Header',{attrs:{"title":"Programs"}},[_c(VBtn,{attrs:{"dark":"","to":{ name: 'AddProgram' },"color":"#ae1431","elevation":"0"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-plus")]),_vm._v(" Add Program ")],1)],1),_c(VCard,{staticClass:"mt-5"},[_c(VSimpleTable,{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Description")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")])])]),_c('tbody',_vm._l((_vm.programs),function(program){return _c('tr',{key:program.id,staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.navigateToEdit(program)}}},[_c('td',[_vm._v(_vm._s(program.name)+" ")]),_c('td',[_vm._v(_vm._s(program.description)+" ")]),_c('td',[_vm._v(_vm._s(program.status)+" ")])])}),0)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }