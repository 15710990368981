import gql from 'graphql-tag';
import apolloClient from '../../apollo/apollo';

const state = {
    departmentStatic: {},
    doctorStatic: {},
    departmentTimes: {},
    doctorTimes: {},
    allDepartmentsBookings: null
};

const getters = {
    // getAllPractitioners: (state) => state.departmentStatic,

};

const mutations = {
    
    setDepartmentStatic(state, departmentStatic) {
        state.departmentStatic = departmentStatic;
    },

    setDoctorStatic(state, doctorStatic) {
        state.doctorStatic = doctorStatic;
    },

    setDepartmentTimes(state, departmentTimes){
        state.departmentTimes = departmentTimes;
    },

    setDoctorTimes(state, doctorTimes){
        state.doctorTimes = doctorTimes;
    },

    setAllDepartmentsBookings(state, allDepartmentsBookings){
        state.allDepartmentsBookings = allDepartmentsBookings
    }
};

const actions = {
    async fetchDepartmentStatic({ commit }, payload) {
        try {
            const response = await apolloClient.query({
                query: gql`
                    query {
                        findOneDepartmentById(id: "${payload.departmentId}") {
                            departmentStatic(dateIn: "${payload.dateIn}") {
                                totalBookingCancel
                                totalBookingNotCancel
                            }
                        }
                    }
                `,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setDepartmentStatic', response.data.findOneDepartmentById.departmentStatic);
        } catch (error) {
            console.error("Failed to fetch DepartmentStatic:", error);
        }
    },

    async fetchDoctorStatic({ commit }, payload) {
        try {
            const response = await apolloClient.query({
                query: gql`
                    query findOneDepartmentById($id: ID!, $listOfUserId: [Int], $dateIn: String!){
                        findOneDepartmentById(id: $id) {
                            doctorsStatic(dateIn: $dateIn ,  listOfUserId: $listOfUserId) {
                                totalBookingCancel
                                totalBookingNotCancel
                                name
                            }
                        }
                    }
                `,
                variables: {
                    id: payload.departmentId,
                    dateIn: payload.dateIn,
                    listOfUserId: payload.userId
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            commit('setDoctorStatic', response.data.findOneDepartmentById.doctorsStatic);

        } catch (error) {
            console.error("Failed to fetch DoctorStatic:", error);
        }
    },

    async fetchDepartmentTimes({ commit }, payload) {
        try {
            const response = await apolloClient.query({
                query: gql`
                    query {
                        findOneDepartmentById(id: "${payload.departmentId}") {
                            departmentStatic(dateIn: "${payload.dateIn}") {
                               busyByHour {
                                    count
                                    name
                                }
                            }
                        }
                    }
                `,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setDepartmentTimes', response.data.findOneDepartmentById.departmentStatic.busyByHour);
        } catch (error) {
            console.error("Failed to fetch DepartmentTimes:", error);
        }
    },

    async fetchDoctorTimes({ commit }, payload) {
        try {
            const response = await apolloClient.query({
                query: gql`
                    query findOneDepartmentById($id: ID!, $listOfUserId: [Int], $dateIn: String!){
                        findOneDepartmentById(id: $id) {
                            doctorsStatic(dateIn: $dateIn ,  listOfUserId: $listOfUserId) {
                                 busyByHour {
                                    count
                                    name
                                }
                            }
                        }
                    }
                `,
                variables: {
                    id: payload.departmentId,
                    dateIn: payload.dateIn,
                    listOfUserId: payload.userId
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });

            commit('setDoctorTimes', response.data.findOneDepartmentById.doctorsStatic[0].busyByHour);

        } catch (error) {
            console.error("Failed to fetch DoctorStatic:", error);
        }
    },

    async fetchAllDepartmentsBookings({ commit }, payload) {
        try {
            const response = await apolloClient.query({
                query: gql`
                    query {
                        findAllDepartments {
                            departmentStatic(dateIn: "${payload.dateIn}") {
                                totalBookingNotCancel
                                name
                            }
                        }
                    }
                `,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            console.log(response.data.findAllDepartments);
            commit('setAllDepartmentsBookings', response.data.findAllDepartments);
        } catch (error) {
            console.error("Failed to fetch AllDepartmentsBookings:", error);
        }
    },


};

export default {
    state,
    getters,
    mutations,
    actions,
};
