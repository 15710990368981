import { render, staticRenderFns } from "./AddPatientDepartmentNote.vue?vue&type=template&id=39612eac&scoped=true"
import script from "./AddPatientDepartmentNote.vue?vue&type=script&lang=js"
export * from "./AddPatientDepartmentNote.vue?vue&type=script&lang=js"
import style0 from "./AddPatientDepartmentNote.vue?vue&type=style&index=0&id=39612eac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39612eac",
  null
  
)

export default component.exports