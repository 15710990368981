<template>
  <div>
    <v-container v-if="!loadingPage" >
      <v-card style="width: 100%" elevation="2">
        <v-card-title class="headline">
          {{ patientFormSign.formSign.name }}
        </v-card-title>
        <v-card-text>
          <v-expand-transition>
            <div v-if="pdfLoaded" class="d-flex justify-center">
              <div ref="pdfPreview" class="pdf-preview"></div>
            </div>
            <v-progress-circular
                v-else
                indeterminate
                color="primary"
                class="mt-4"
            ></v-progress-circular>
          </v-expand-transition>
        </v-card-text>
      </v-card>

      <form>
        <div v-if="forms && forms.length" v-for="(section, sectionIndex) in forms" :key="sectionIndex" class="mb-6">
          <v-card elevation="2">
            <v-card-title class="subtitle-1 font-weight-bold">{{section.section}}</v-card-title>
            <v-card-text>
              <br />
              <v-row>
                <v-col v-if="section.items && section.items.length" v-for="(form, formIndex) in section.items" :key="formIndex" cols="12">
                  <div v-if="form.type === 'sign'" class="signature-container">
                    <v-label class="mb-2">{{ form.label }}</v-label>
                    <div class="signature-box">
                      <canvas :ref="'signatureCanvas-' + sectionIndex + '-' + formIndex"></canvas>
                    </div>
                    <div class="mt-2">
                      <v-btn @click="clearSignature(sectionIndex, formIndex)" small outlined>Clear</v-btn>
                    </div>
                  </div>
                  <v-text-field
                      v-else
                      :label="form.label"
                      v-model="form.value"
                      :type="form.type"
                      outlined
                      dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>

        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
                @click="editPatientFormSign"
                class="mt-7"
                dark
                color="#ae1431"
                elevation="0"
                block
            >
              <v-icon left>mdi-check</v-icon>
              Save edit
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
                class="mt-7"
                outlined
                elevation="0"
                @click="cancelHandler"
                block
            >
              <v-icon left>mdi-close</v-icon>
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </form>
      <br />
      <br />
      <br />
      <br />
    </v-container>
    <v-progress-circular
        v-if="loadingPage"
        color="primary"
        indeterminate
        class="loading-indicator"
    ></v-progress-circular>
  </div>
</template>

<script>
import {PDFDocument} from 'pdf-lib'
import SignaturePad from 'signature_pad'
import * as pdfjsLib from 'pdfjs-dist'
import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";
import _ from 'lodash'

export default {
  data() {
    return {
      loadingPage: false,
      errorMessages: null,
      forms: [],
      patientFormSign: null,
      pdfDoc: null,
      signaturePads: {},
      pdfLoaded: false,
      namePage: 1,
      totalPages: 0,
    }
  },

  async mounted() {
    try {
      pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.mjs');
    } catch (e) {
      console.error("Error setting up PDF.js worker:", e);
    }

    try {
      const response = await apolloClient.query({
        query: gql`
          query findOnePatientFormSignByPatientFormSignId($patientFormSignId: Int!) {
            findOnePatientFormSignByPatientFormSignId(patientFormSignId: $patientFormSignId) {
             id
             pdfUrl
             formJsonAnswers{
              name
              value
             }
             formSign{
                id
                name
                pdfUrl
                formJsonQuestion{
                  name
                  type
                  label
                  order
                  section
                  }
              }
            }
          }
        `,
        variables: {
          patientFormSignId: this.$route.params.patientFormSignId
        },
        context: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        },
      });

      if (response.data && response.data.findOnePatientFormSignByPatientFormSignId) {


        this.patientFormSign = response.data.findOnePatientFormSignByPatientFormSignId;
        if(this.patientFormSign.pdfUrl!=null){
          window.open(this.patientFormSign.pdfUrl,'_blank');
          window.setTimeout(function(){this.close();},500)
          return;
        }


        let answersMap = new Map();
        try {
          answersMap = new Map(this.patientFormSign.formJsonAnswers.map(answer => [answer.name, answer]));
        } catch (e) {
          console.error('Error creating answersMap:', e);
        }

        const formItems = this.patientFormSign.formSign.formJsonQuestion.map(item => {
          const answer = answersMap.get(item.name);

          let value=answer ? answer.value : null;
          if(item.type==='sign' && value!=null){
           value = null;

          }
          return {
            name: item.name,
            value: value,
            type: item.type,
            label: item.label,
            section: item.section,
            order: item.order
          };
        });

        const grouped = _.groupBy(formItems, 'section');
        console.log('Grouped items:', grouped);

        this.forms = Object.keys(grouped).map(key => ({
          section: '' + key,
          items: grouped[key] || []  // Ensure items is always an array
        }));

        console.log('Processed forms:', JSON.parse(JSON.stringify(this.forms)));

        this.$nextTick(() => {
          this.loadPDF();
          this.initializeSignaturePads();
        });
      } else {
        throw new Error("No data returned from the query");
      }
    } catch (error) {
      console.error("Failed to fetch form sign:", error);
      this.errorMessages = `Failed to fetch form sign: ${error.message}`;
    }
  },
  methods: {
    cancelHandler() {
      this.$router.back()
    },
    async editPatientFormSign() {
      try {
        this.loadingPage = true;
        const result = await this.$store.dispatch('editPatientFormSign', {
          forms: this.forms,
          patientFormSignId: this.patientFormSign.id
        });

        this.loadingPage = false;

        if (result === true) {
          window.close();
        } else {
          throw new Error("Failed to edit patient form sign");
        }
      } catch (error) {
        console.error("Error editing patient form sign:", error);
        this.errorMessages = `Failed to edit patient form sign: ${error.message}`;
      }
    },
    async loadPDF() {
      try {
        const fullurl = this.patientFormSign.formSign.pdfUrl
        console.log("Attempting to load PDF from URL:", fullurl);
        const response = await fetch(fullurl);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/pdf')) {
          console.warn("The content type is not PDF. Content-Type:", contentType);
        }
        const arrayBuffer = await response.arrayBuffer();
        if (arrayBuffer.byteLength === 0) {
          throw new Error("The PDF file is empty");
        }

        console.log("PDF file size:", arrayBuffer.byteLength, "bytes");

        try {
          this.pdfDoc = await PDFDocument.load(arrayBuffer);
        } catch (pdfLibError) {
          console.error("pdf-lib failed to load the PDF:", pdfLibError);

          const loadingTask = pdfjsLib.getDocument({data: arrayBuffer});
          const pdf = await loadingTask.promise;
          console.log("PDF loaded successfully with pdf.js");
          this.totalPages = pdf.numPages;
          this.pdfLoaded = true;
          this.$nextTick(() => {
            this.renderPDF(pdf);
          });
          return;
        }

        this.pdfLoaded = true;
        this.totalPages = this.pdfDoc.getPageCount();
        this.$nextTick(() => {
          this.renderPDF();
        });
      } catch (error) {
        console.error("Failed to load PDF:", error);
        this.errorMessages = `Failed to load PDF: ${error.message}`;
      }
    },

    async renderPDF(pdfDoc = null) {
      try {
        let pdf;
        if (pdfDoc) {
          pdf = pdfDoc;  // Use the pdf.js document if provided
        } else {
          const fullurl = this.patientFormSign.formSign.pdfUrl;

          const loadingTask = pdfjsLib.getDocument(fullurl);
          pdf = await loadingTask.promise;
        }
        this.totalPages = pdf.numPages;

        const pdfPreview = this.$refs.pdfPreview;
        pdfPreview.innerHTML = '';

        for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const scale = 1.5;
          const viewport = page.getViewport({scale});

          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          await page.render(renderContext);

          const pageContainer = document.createElement('div');
          pageContainer.className = 'pdf-page ';
          pageContainer.appendChild(canvas);

          const pageNumber = document.createElement('div');

          pageNumber.className = 'page-number ';
          pageNumber.textContent = `Page ${pageNum}`;
          pageContainer.appendChild(pageNumber);
          pdfPreview.appendChild(pageContainer);
        }
      } catch (error) {
        console.error("Failed to render PDF:", error);
        this.errorMessages = `Failed to render PDF: ${error.message}`;
      }
    },
    initializeSignaturePads() {
      this.forms.forEach((section, sectionIndex) => {
        section.items.forEach((form, formIndex) => {
          if (form.type === 'sign') {
            const canvasRef = this.$refs[`signatureCanvas-${sectionIndex}-${formIndex}`][0];
            if (canvasRef) {
              const signaturePad = new SignaturePad(canvasRef, {
                minWidth: 1,
                maxWidth: 2,
                penColor: "rgb(0, 0, 0)"
              });

              // Add event listeners to the canvas
              canvasRef.addEventListener('mouseup', this.onSignatureEnd.bind(this, sectionIndex, formIndex, signaturePad));
              canvasRef.addEventListener('touchend', this.onSignatureEnd.bind(this, sectionIndex, formIndex, signaturePad));

              if (form.value) {
                this.loadSignatureImage(signaturePad, form.value);
              }

              this.signaturePads[`${sectionIndex}-${formIndex}`] = signaturePad;
            }
          }
        });
      });
    },

    onSignatureEnd(sectionIndex, formIndex, signaturePad) {
      if (!signaturePad.isEmpty()) {
        this.forms[sectionIndex].items[formIndex].value = signaturePad.toDataURL();
        console.log('Signature ended:', this.forms[sectionIndex].items[formIndex].value);
      }
    },
    clearSignature(sectionIndex, formIndex) {
      const signaturePad = this.signaturePads[`${sectionIndex}-${formIndex}`];
      if (signaturePad) {
        signaturePad.clear();
        this.forms[sectionIndex].items[formIndex].value = null;
      }
    },
    loadSignatureImage(signaturePad, dataUrl) {
      return new Promise((resolve, reject) => {
        if (!signaturePad || !signaturePad._canvas) {
          console.error('SignaturePad or canvas is not available');
          reject('Canvas not available');
          return;
        }

        const canvas = signaturePad._canvas;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          console.error('Unable to get 2D context from canvas');
          reject('Context not available');
          return;
        }

        const image = new Image();
        image.onload = () => {
          signaturePad.clear(); // Clear existing signature
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          signaturePad._isEmpty = false;
          resolve();
        };
        image.onerror = () => {
          console.error('Failed to load signature image');
          reject('Image load failed');
        };
        image.src = dataUrl;
      });
    },
  },
}
</script>

<style scoped>
.pdf-preview {
  max-width: 100%;
  overflow-x: auto;
}

.pdf-page {
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pdf-page canvas {
  max-width: 100%;
  height: auto;
}

.page-number {
  text-align: center!important;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.signature-container {
  display: flex;
  flex-direction: column;
}

.signature-box {
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  padding: 10px;
  width: 300px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}



.v-card__title {
  background-color: #a8e3e1;
}
</style>
