<template>
  <v-app>
    <NavBar />
    <router-view class="px-9 py-9"/>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "WithNavbar",
  components: { NavBar },
};
</script>

<style>
.v-application--wrap {
  min-height: initial;
}

header.px-5.py-5.v-sheet.theme--dark.elevation-0.v-toolbar.v-app-bar.v-app-bar--fixed {
  padding: 0 !important;
  display: flex;
}
</style>