<template>
  <v-app>
    <Header :title=breadcrumb></Header>
<!--    <router-view class="px-9 py-9"/>-->
    <v-row>
      <v-col cols="12"  sm="12" md="2" lg="2" xl="2" >
        <v-card
            class="mx-auto"
            tile
        >
          <v-list dense>
            <v-list-item-group
                color="primary"
            >
              <v-list-item
                  v-for="item in navItems"
                  :key="item.title"
                  :to="item.route"
                  active-class="link-active"
                  exact
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col >
        <router-view />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import Header from "@/components/Header";

export default {
  props: ["id"],
  components: {Header},
  data() {
    return {
      breadcrumb: "Patient Details",
      navItems: [
        {title: "Patient Details",  route: `/patients/${this.$route.params.id}`},
        {title: "Appointments", route: `/patients/${this.$route.params.id}/appointments`},
        {title: "Forms", route: `/patients/${this.$route.params.id}/form-signatures`},
        {title: "Department Note", route: `/patients/${this.$route.params.id}/departments`},
        {title: "Treatment Note", route: `/patients/${this.$route.params.id}/treatment-notes`},

      ],
    };
  },
}
</script>

<style scoped>

</style>
