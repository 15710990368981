<template>
  <v-app>
    <v-navigation-drawer
        :mini-variant.sync="mini"
        v-model="inputValue"
        app
        color="#14AAA6"
        dark
        floating
        class="drawerclass"
        persistent
        :permanent="isLargeScreen"
        mobile-breakpoint="1070"
        width="200"

      >
      <v-list-item style="background-color: white">
        <v-list-item-content style="padding: 8px 0" class="d-flex justify-center">
          <v-img src="@/assets/logo2.png" class="rounded-lg" max-width="100"></v-img>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense nav class="pl-0 pr-5" shaped>
        <v-list-item
            v-for="item in navItems"
            :key="item.title"
            :to="item.route"
            v-if="$can(item.permission) "
            active-class="active-item"
        >

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="profile-panel">
      <v-expansion-panels>
        <v-expansion-panel class="rounded-0" color="white">
          <v-expansion-panel-header>{{ loggedIn.contact.firstName }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item
                  link
                  :to="{ name: 'EditCurrentUserProfile'}"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                  link
                  @click="logOut"
              >
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </div>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex';
import {Ability, AbilityBuilder} from "@casl/ability";

export default {
  data() {
    return {
      navItems: [],
      permissions: [],
      drawer: true,
      mini: false,
      loggedIn: this.$store.getters.getLoggedUser
    };
  },
  async mounted() {

    const permissions = JSON.parse(localStorage.getItem('permissions') || []);
    if (permissions && permissions.length > 0) {
      this.permissions = permissions;
      this.navItems = [
        {title: "Dashboard", icon: "mdi-view-dashboard", route: "/", permission: 'dashboard_show'},
        {
          title: "Appointements",
          icon: "mdi-calendar",
          route: "/appointements",
          permission:'appointment_show'
        },
        {
          title: "Patients",
          icon: "mdi-account-injury",
          route: "/patients",
          permission: 'patient_show'
        },
        {title: "Settings", icon: "mdi-cog", route: "/settings", permission: 'setting_show'},
        {title: "Statistics", icon: "mdi-chart-bar-stacked", route: "/statistics", permission: 'setting_show'},

      ]
    }


  },
  created() {
    const { can, rules } = new AbilityBuilder(Ability);

    can(JSON.parse(localStorage.getItem('permissions')));
    const currentUser=JSON.parse(localStorage.getItem('user'))
    can('user_'+currentUser.id)
    const ability = new Ability(rules);
    this.$ability.update(ability.rules);


  },
  beforeCreate() {

  },


  methods: {

    logOut() {
      this.$store.dispatch('logout')
      this.$router.push({name: 'Login'})
    },


    editUser() {
      this.$router.push({name: 'EditUser', params: {id: this.$store.getters.getLoggedUser.id}})
    }
  },
  computed: {
    ...mapGetters(['getDataFromRouterView','getPermissions','getDrawer']),
    dataFromRouterView() {
      return this.getDataFromRouterView;
    },
    isLargeScreen() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    inputValue: {
      get() {
        return this.$store.getters.getDrawer;
      },
      set(val) {
        this.$store.dispatch("setDrawerAction", val);
      },
    },
  },
  watch:{
    isLargeScreen(val) {
      if (val) {
       this.$store.dispatch('setDrawerAction', val)
      }
    },
    getPermissions(permissions){
      const currentUser=JSON.parse(localStorage.getItem('user'))

      const { can, rules } = new AbilityBuilder(Ability);
      can(permissions);
      can('user_'+currentUser.id)
      const ability = new Ability(rules);
      this.$ability.update(ability.rules);
    }
  }
};
</script>

<style>
.active-item {
  background-color: #004a4a;
  /* border-radius: 0 15px 15px 0 !important; */
}

.profile-panel .v-expansion-panels {
  position: absolute;
  bottom: 0;
}

.profile-panel .v-expansion-panel-header {
  background-color: #3d7575;
}

.profile-panel .v-expansion-panel-content__wrap {
  padding: 0;
  background-color: #458888;
}

.v-list-item__icon {
  margin-right: 12px !important;
}

.drawerclass{
  border-right: 1.5px solid #e0e0e0 !important;
}
</style>
