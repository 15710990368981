<template>
  <v-app>
    <Header title="Add Program"></Header>
    <v-form ref="form" lazy-validation>
      <!--      <div class="d-flex justify-start">-->

        <div>
          <v-text-field
              label="Name"
              style="max-width: 400px"
              outlined
              class="text-right pa-4"
              v-model="form.name"
              :rules="[() => !!form.name || 'This field is required']"
              :error-messages="errorMessages"
              required
          ></v-text-field>
        </div>
      <div>
        <v-text-field
            label="Name English"
            style="max-width: 400px"
            outlined
            class="text-right pa-4"
            v-model="form.nameEn"
        ></v-text-field>
      </div>
        <div>
          <v-textarea
              outlined
              label="description"
              style="max-width: 400px"
              class="text-right pa-4"
              v-model="form.description"
          ></v-textarea>
        </div>
        <div style="max-width: 400px">
          <v-select
              outlined
              label="Status"
              :items="['ACTIVE','DISABLE']"
              v-model="form.status"
          ></v-select>

        </div>
      <v-btn @click="createForm" class="mt-7" dark color="#ae1431" elevation="0"
      >Add Fom Sign
      </v-btn
      >

      <v-btn
          class="mt-7 ml-3"
          outlined
          elevation="0"
          :to="{ name: 'ViewProgram' }"
      >Cancel
      </v-btn
      >
    </v-form>
  </v-app>
</template>

<script>
import {mapState} from "vuex";
import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";
import Header from "@/components/Header.vue";
import appointmentTypeService from "@/store/modules/appointmentTypeService";

export default {
  data() {
    return {
      form: {
        name: '',
        nameEn: '',
        description: '',
        status: 'ACTIVE'

      },
      errorMessages: "",

    };
  },
  components: {Header},

  methods: {
    async createForm() {
      const result = await this.$store.dispatch('createProgram', this.form)

      if (result === true) {
        this.$router.back();
      }
    }
  },
  mounted() {

  },
  computed: {},

};
</script>

<style scoped>
.v-card__title {
  background-color: #a8e3e1;
}
</style>
