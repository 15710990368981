import { VApp } from 'vuetify/lib/components/VApp';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VNavigationDrawer,{staticClass:"drawerclass",attrs:{"mini-variant":_vm.mini,"app":"","color":"#14AAA6","dark":"","floating":"","persistent":"","permanent":_vm.isLargeScreen,"mobile-breakpoint":"1070","width":"200"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},[_c(VListItem,{staticStyle:{"background-color":"white"}},[_c(VListItemContent,{staticClass:"d-flex justify-center",staticStyle:{"padding":"8px 0"}},[_c(VImg,{staticClass:"rounded-lg",attrs:{"src":require("@/assets/logo2.png"),"max-width":"100"}})],1)],1),_c(VDivider),_c(VList,{staticClass:"pl-0 pr-5",attrs:{"dense":"","nav":"","shaped":""}},_vm._l((_vm.navItems),function(item){return (_vm.$can(item.permission) )?_c(VListItem,{key:item.title,attrs:{"to":item.route,"active-class":"active-item"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1):_vm._e()}),1),_c('div',{staticClass:"profile-panel"},[_c(VExpansionPanels,[_c(VExpansionPanel,{staticClass:"rounded-0",attrs:{"color":"white"}},[_c(VExpansionPanelHeader,[_vm._v(_vm._s(_vm.loggedIn.contact.firstName))]),_c(VExpansionPanelContent,[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"link":"","to":{ name: 'EditCurrentUserProfile'}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-account")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("profile")])],1)],1),_c(VListItem,{attrs:{"link":""},on:{"click":_vm.logOut}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-logout")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }