import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[(_vm.$route.path == '/')?_c('Header',{attrs:{"title":_vm.$t('name')}}):_vm._e(),_c('notifications',{attrs:{"width":"40%","animation-name":"v-fade","group":"failmessages","position":"bottom right"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c(VAlert,{staticClass:"px-0 py-0 mx-3 FailMessages"},[_c(VLayout,{staticStyle:{"width":"100% !important","justify-content":"space-between","height":"100%","align-items":"center"}},[_c('span',{staticStyle:{"margin":"auto 18px"}},[_vm._v(" "+_vm._s(_vm.$t(props.item.text)))]),_c(VBtn,{staticClass:"customizedBtn cloeicon px-2",staticStyle:{"margin":"14px"},attrs:{"text":"","small":""},on:{"click":props.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)]}}])}),_c('notifications',{attrs:{"width":"40%","group":"successmesages","position":"bottom right"},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c(VAlert,{staticClass:"mx-3 px-0 py-0 SuccessMesages"},[_c(VLayout,{staticStyle:{"width":"100% !important","justify-content":"space-between","height":"100%","align-items":"center"}},[_c('span',{staticStyle:{"margin":"auto 18px"}},[_vm._v(" "+_vm._s(_vm.$t(props.item.text)))]),_c(VBtn,{staticClass:"customizedBtn cloeicon px-2",staticStyle:{"margin":"12px"},attrs:{"text":"","small":""},on:{"click":props.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)]}}])}),_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }