import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";

const state = {
    roles: [],
    role: null,
};

const getters = {

};

const mutations = {


}

const actions = {

    async updateRole({commit}, form) {
        console.log(form);
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation updateRole($id: ID!,$roleInput: RoleInput!) {
                        updateRole(id: $id,roleInput: $roleInput) {
                            id
                            name
                        }
                    }
                `,
                variables: {
                    id: form.id,
                    roleInput: {
                        name: form.name,
                        listOfPageResourcePermissionId: form.listOfPageResourcePermissionId
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });

            if(response.errors){
                return false
            }

        } catch (error) {
            console.log(error.message);
            return false;
        }

        return true;
    },
    async createRole({commit}, form) {



        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createRole($roleInput: RoleInput!) {
                        createRole(roleInput: $roleInput) {
                            id
                            name
                        }
                    }
                `,
                variables: {
                    roleInput: {
                        name: form.name,
                        listOfPageResourcePermissionId: form.listOfPageResourcePermissionId,
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });

            if(response.errors){
                return false
            }
        } catch (e) {
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            return false;
        }



        return true;
    }

}

export default {
    state,
    getters,
    mutations,
    actions,
};

