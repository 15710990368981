import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";

const state = {
    programs: [],

};

const getters = {

};

const mutations = {


}

const actions = {

    async createProgram({commit}, form) {



        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation createNewProgram($form: ProgramInput!) {
                        createNewProgram(programInput: $form) {
                            id
                        }
                    }
                `,
                variables: {
                    form: form
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });

            if(response.errors){
                return false
            }
        } catch (e) {
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            return false;
        }

        return true;
    },
    async editProgram({commit}, form) {



        try {
            const response = await apolloClient.mutate({
                mutation: gql`
                    mutation editProgram($id: Int!,$form: ProgramInput!) {
                        editProgram(id: $id,programInput: $form) {
                            id
                        }
                    }
                `,
                variables: {
                    form: form,
                    id: form.id
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    }
                }
            });

            if(response.errors){
                return false
            }
        } catch (e) {
            console.log("Error", e.stack);
            console.log("Error", e.name);
            console.log("Error", e.message);
            return false;
        }

        return true;
    }

}

export default {
    state,
    getters,
    mutations,
    actions,
};

