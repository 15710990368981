import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('Header',{attrs:{"title":_vm.breadcrumb}}),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"12","md":"2","lg":"2","xl":"2"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"tile":""}},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"}},_vm._l((_vm.navItems),function(item){return _c(VListItem,{key:item.title,attrs:{"to":item.route,"active-class":"link-active","exact":""}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1)],1),_c(VCol,[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }