import Vue from 'vue'
import Vuex from 'vuex'
import users from '../store/modules/users';
import appointmentTypeServices from '../store/modules/appointmentTypeService';
import patients from '../store/modules/patients';
import countries from '../store/modules/countries';
import appointementsBooking from '../store/modules/appointementsBooking';
import VuexPersistence from 'vuex-persist'
import departments from "@/store/modules/departments";
import roles from "@/store/modules/roles";
import app from "@/store/modules/app";
import statistics from '../store/modules/statistics';
import * as LocalForage from "localforage";
import formSign from "@/store/modules/formSign";
import departmentsNote from "@/store/modules/departmentsNote";
import treatmentNote from "@/store/modules/treatmentNote";
import programs from "@/store/modules/programs";
Vue.use(Vuex)

LocalForage.config({
  driver: LocalForage.INDEXEDDB,
  name: 'mrs'
});
const vuexLocal = new VuexPersistence({
  key: 'keyvaluepairs',
  storage: LocalForage,
  asyncStorage: true
})



export default new Vuex.Store({
  plugins: [vuexLocal.plugin],

  modules: {
    users,
    app,
    formSign,
    appointementsBooking,
    patients,
    countries,
    appointmentTypeServices,
    departments,
    programs,
    departmentsNote,
    treatmentNote,
    roles,
    statistics
  },

  // actions: {
  //   clearLocal() {
  //     LocalForage.removeItem('mrs')
  //     LocalForage.clear()
  //     alert('cleared index')
  //   }
  // }
})
