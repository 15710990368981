import { render, staticRenderFns } from "./EditPatientFormSignWithoutSidbar.vue?vue&type=template&id=336c1d02&scoped=true"
import script from "./EditPatientFormSignWithoutSidbar.vue?vue&type=script&lang=js"
export * from "./EditPatientFormSignWithoutSidbar.vue?vue&type=script&lang=js"
import style0 from "./EditPatientFormSignWithoutSidbar.vue?vue&type=style&index=0&id=336c1d02&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "336c1d02",
  null
  
)

export default component.exports