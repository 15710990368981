<template>
  <v-app>
    <Header title="Programs">
      <v-btn dark :to="{ name: 'AddProgram' }" color="#ae1431" elevation="0">
        <v-icon left> mdi-plus</v-icon>
        Add Program
      </v-btn>
    </Header>

    <v-card class="mt-5">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Description</th>
            <th class="text-left">Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="program in programs" :key="program.id" @click="navigateToEdit(program)" style="cursor: pointer">
            <td>{{ program.name }} </td>
            <td>{{ program.description }} </td>
            <td>{{ program.status }} </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-app>
</template>

<script>
import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";
import Header from "@/components/Header.vue";

export default {
  components: {Header},
  data() {
    return {
      programs: [],
    }
  },
  methods: {
    async navigateToEdit(program) {
      this.$router.push({name: 'EditProgram', params: {id: program.id}});

    },
  },
  mounted() {
    try {
      apolloClient.query({
        query: gql`
          query findAllPrograms {
          findAllPrograms{
             id
             name
             description
             status
           }
          }
        `,
        context: {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
          },
        },
      }).then(response => {
        this.programs = response.data.findAllPrograms;
      });

    } catch (error) {
      console.error("Failed to fetch programs:", error);
    }
  }
};
</script>

<style>
.v-toolbar__content,
.v-toolbar__extension {
  justify-content: space-between;
  width: -webkit-fill-available;
}

thead tr {
  background-color: #a8e3e1;
}
</style>
