import gql from 'graphql-tag';
import apolloClient from '../../apollo/apollo';
import departments from './departments';

const state = {
    practitioners: null,
    services: [],
    bookings: [],
    booking: null,
    patients: [],
    loading: false,
    loadingBooking: false,
    departments: null
};

const getters = {
    getAllPractitioners: (state) => state.practitioners,
    getAllServices: (state) => state.services,
    getAllBookings: (state) => state.bookings,
    getBooking: (state) => state.booking,
    getAllPatient: (state) => state.patients
};

const mutations = {
    setAllPractitioners(state, practitioners) {
        state.practitioners = practitioners;
    },

    setAllServices(state, services) {
        state.services = services;
    },

    setAllBookings(state, bookings) {
        state.bookings = bookings;
    },

    setBooking(state, booking) {
        const index = state.bookings.findIndex(b => b.id === booking.id);
        if (index !== -1) {
            state.bookings[index] = booking;
        } else {
            state.bookings.push(booking);
        }
        state.booking = booking;
    },

    setAllPatient(state, patients) {
        state.patients = patients
    },

    setLoading(state, loading) {
        state.loading = loading
    },
    setLoadingBooking(state, loadingBooking) {
      state.loadingBooking = loadingBooking
      // console.log(state.loadingBooking, "loadingBooking from commit");
    },
    setDepartments(state, departments) {
        state.departments = departments
    }
};

const actions = {
    async fetchPractitionersByDepartment({commit}, departmentId) {
        try {
          commit('setLoading', true);
            const response = await apolloClient.query({
                query: gql`
          query findAllUsersByDepartmentId {
            findAllUsersByDepartmentId(departmentId: ${departmentId}, userFilter: {userType: DOCTOR}) {
              id
              email
              contact {
              fullName
                firstName
                lastName
                email
              }
              listOfDayAvailable {
                availables {
                  fromTime
                  toTime
                }
                day
                isActive
                 breaks {
                  fromTime
                  toTime
                }
              }
              listOfAppointmentTypeService {
                color
                durationInMint
                id
                maxNumberPatient
                name
              }
              role
            }
          }
        `,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setAllPractitioners', response.data.findAllUsersByDepartmentId);
            commit('setLoading', false);
        } catch (error) {
            console.error("Failed to fetch Practitioners:", error);
        }
    },

    async fetchAllServices({commit}) {
        try {
            const response = await apolloClient.query({
                query: gql`
          query MyQuery {
          findAllAppointmentTypeServices {
            color
            durationInMint
            id
            maxNumberPatient
            name
            users {
              id
              contact {
              fullName
                firstName
                lastName
              }
            }
          }
        }
        `,
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setAllPractitioners', response.data.findAllUsers);
        } catch (error) {
            console.error("Failed to fetch Practitioners:", error);
        }
    },

    async fetchAllBookings({commit}, payload) {

        if(payload.end!=null && payload.end!=undefined){
            let d = new Date(payload.end);
            d.setDate(d.getDate() - 1);
           payload.end = d.toISOString().split('T')[0].replace(/-/g, '-');
        }

        try {
          commit('setLoadingBooking', true);

            const response = await apolloClient.query({
                query: gql`
          query findAllBooking($fromDate: String, $toDate: String, $listOfUserId: [Int!], $departmentId: Int!) {
          findAllBooking(
            bookingFilter: {departmentId: $departmentId, fromDate: $fromDate, toDate: $toDate,listOfUserId: $listOfUserId}
          ) {
            id
            bookingDate
            endTime
            startTime
            maxPatient
            appointmentTypeService {
              category
              color
              id
              maxNumberPatient
              durationInMint
              description
              name
            }
            user {
              contact {
              fullName
                firstName
                lastName
              }
              id
              email
            }
              attendees {
                notes
                id
                cancellationNote
                cancellationReason
                patientId
                patient {
                  id
                  contact {
                    firstName
                    lastName
                  }
                }
                patientArriveStatus
              }
          }
        }
        `,
                variables: {
                    fromDate: payload?.start ??null,
                    toDate: payload?.end?? null,
                    listOfUserId: payload?.listOfUserId ?? [],
                    departmentId: payload?.departmentId ?? null
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
                    },
                },
            });
            commit('setAllBookings', response.data.findAllBooking);
            commit('setLoadingBooking', false);
        } catch (error) {
            console.error("Failed to fetch Bookings:", error);
        }
    },

    async addBooking({commit, dispatch}, payload) {

        const booking = payload.booking;

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation createBooking($endTime: String!, $startTime: String!, $bookingDate: String!, $userId: Int!, $serviceId: Int!, $maxPatient: Int!, $repeatBy: RepeatedByEnum!, $endAfter: Int!, $repeatEvery: Int!, $departmentId: Int!) {
            createBooking(bookingInput: {departmentId: $departmentId, endTime: $endTime, startTime: $startTime, bookingDate: $bookingDate, userId: $userId, appointmentTypeServiceId: $serviceId, maxPatient: $maxPatient, repeatBy: $repeatBy, endAfter: $endAfter, repeatEvery: $repeatEvery}) {
              id
              bookingDate
              endTime
              startTime
              appointmentTypeService {
                category
                color
                id
                maxNumberPatient
                durationInMint
                description
                name
              }
              user {
                contact {
                  firstName
                  lastName
                }
                email
                id
              }
              attendees {
                notes
                id
                cancellationNote
                cancellationReason
                patientId
                patient {
                  id
                  contact {
                    firstName
                    lastName
                  }
                }
                patientArriveStatus
              }
            }
          }
        `,
                variables: {
                    endTime: booking.end,
                    startTime: booking.start,
                    bookingDate: booking.date,
                    userId: booking.category,
                    serviceId: booking.service.id,
                    maxPatient: booking.service.maxNumberPatient,
                    repeatBy: booking.repeatBy,
                    endAfter: booking.endAfter,
                    repeatEvery: booking.repeatEvery,
                    departmentId: payload.departmentId
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                    },
                },
            });
            // commit('setBooking', response.data.createBooking);
            console.log(payload);
            dispatch('fetchAllBookings',{start: payload.start, end: payload.end, listOfUserId: payload.listOfUserId, departmentId: payload.departmentId});

        } catch (error) {
            console.error('Failed to create Booking:', error.message);
        }
    },

    async fetchSingleBooking({commit}, id) {
        try {
            const response = await apolloClient.query({
                query: gql`
          query findOneBookingById($bookingId: ID!) {
            findOneBookingById(id: $bookingId) {
            appointmentTypeService {
              durationInMint
              name
              id
            }
            bookingDate
            maxPatient
            endTime
            id
            startTime
            repeatBy
            endAfter
            repeatEvery
            userId
            user {
            contact {
              firstName
              lastName
            }
          }
            attendees {
            notes
            cancellationNote
            cancellationReason
            id
            patientId
            patient {
              contact {
                firstName
                lastName
              }
            }
            patientArriveStatus
          }
          }
          }
        `,
                variables: {bookingId: id},
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                    },
                },
            });
            console.log(response.data.findOneBookingById, "booking - fetchSingleBooking");
            commit('setBooking', response.data.findOneBookingById);
        } catch (error) {
            console.error('Failed to fetch a Booking:', error);
        }
    },

    async updateBooking({commit, dispatch}, {booking, patients,start,end, listOfUserId, departmentId}) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation updateBooking($id: Int!, $endTime: String!, $startTime: String!, $bookingDate: String!, $userId: Int!, $serviceId: Int!, $maxPatient: Int!, $patientsId: [BookingPatientInput]!, $repeatBy: RepeatedByEnum!, $endAfter: Int!, $repeatEvery: Int!, $applyToAllOccurrence: Int!, $departmentId: Int!) {
            updateBooking(
              id: $id
              bookingInput: {
                departmentId: $departmentId,
                endTime: $endTime,
                maxPatient: $maxPatient,
                listOfPatients: $patientsId,
                appointmentTypeServiceId: $serviceId,
                userId: $userId,
                bookingDate: $bookingDate,
                startTime: $startTime,
                repeatBy: $repeatBy,
                repeatEvery: $repeatEvery,
                endAfter: $endAfter,
                applyAttendanceToAllOccurrence: 0, 
                applyToAllOccurrence: $applyToAllOccurrence
              }
            ) {
              appointmentTypeService {
                durationInMint
                name
                id
              }
              bookingDate
              maxPatient
              endTime
              id
              startTime
              repeatBy
              endAfter
              repeatEvery
              userId
              user {
                contact {
                  firstName
                  lastName
                }
              }
              attendees {
                notes
                id
                cancellationNote
                cancellationReason
                patientId
                patient {
                  contact {
                    firstName
                    lastName
                  }
                }
                patientArriveStatus
              }
            }
          }
        `,
                variables: {
                    id: booking.id,
                    endTime: booking.endTime,
                    startTime: booking.startTime,
                    bookingDate: booking.bookingDate,
                    userId: booking.userId,
                    serviceId: booking.appointmentTypeService.id,
                    maxPatient: booking.maxPatient,
                    patientsId: patients,
                    repeatBy: booking.repeatBy,
                    endAfter: booking.endAfter,
                    repeatEvery: booking.repeatEvery,
                    applyToAllOccurrence: booking.applyToAllOccurrence,
                    departmentId: departmentId
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                    },
                },
            });
            commit('setBooking', response.data.updateBooking);
            dispatch('fetchAllBookings',{start: start, end: end, listOfUserId: listOfUserId, departmentId: departmentId});
        } catch (error) {
            console.error('Failed to update booking', error.message);
        }

    },
    async addPatients({commit, dispatch}, {booking, patients,start,end,listOfUserId, departmentId}) {
        console.log(booking.applyAttendanceToAllOccurrence, "applyAttendanceToAllOccurrence");
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation updateBooking($departmentId: Int!, $id: Int!, $endTime: String!, $startTime: String!, $bookingDate: String!, $userId: Int!, $serviceId: Int!, $maxPatient: Int!, $patientsId: [BookingPatientInput]!, $repeatBy: RepeatedByEnum!, $endAfter: Int!, $repeatEvery: Int!, $applyAttendanceToAllOccurrence: Int!) {
            updateBooking(
              id: $id
              bookingInput: {
                departmentId: $departmentId, 
                listOfPatients: $patientsId,
                endTime: $endTime,
                maxPatient: $maxPatient,
                appointmentTypeServiceId: $serviceId,
                userId: $userId,
                bookingDate: $bookingDate,
                startTime: $startTime,
                repeatBy: $repeatBy,
                repeatEvery: $repeatEvery,
                endAfter: $endAfter,
                applyAttendanceToAllOccurrence: $applyAttendanceToAllOccurrence,
                applyToAllOccurrence: $applyAttendanceToAllOccurrence
              }
            ) {
              appointmentTypeService {
                durationInMint
                name
                id
              }
              bookingDate
              maxPatient
              endTime
              id
              startTime
              repeatBy
              endAfter
              repeatEvery
              userId
              user {
                contact {
                  firstName
                  lastName
                }
              }
              attendees {
                notes
                id
                cancellationNote
                cancellationReason
                patientId
                patient {
                  contact {
                    firstName
                    lastName
                  }
                }
                patientArriveStatus
              }
            }
          }
        `,
                variables: {
                    id: booking.id,
                    endTime: booking.endTime,
                    startTime: booking.startTime,
                    bookingDate: booking.bookingDate,
                    userId: booking.userId,
                    serviceId: booking.appointmentTypeService.id,
                    maxPatient: booking.maxPatient,
                    repeatBy: booking.repeatBy,
                    endAfter: booking.endAfter,
                    repeatEvery: booking.repeatEvery,
                    applyAttendanceToAllOccurrence: booking.applyAttendanceToAllOccurrence,
                    patientsId: patients,
                    departmentId: departmentId
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                    },
                },
            });
            // console.log(response.data.updateBooking);
            commit('setBooking', response.data.updateBooking);
            dispatch('fetchAllBookings',{start,end,listOfUserId,departmentId});

            // console.log(patients, "patients");
            // console.log(booking, "booking");

        } catch (error) {
            console.error('Failed to add patients:', error.message);
        }

    },

    async fetchAllPatient({commit}, payload) {
        try {
            const response = await apolloClient.query({
                query: gql`
          query findAllPatient($patientFilter: PatientFilter) {
            findAllPatient(patientFilter: $patientFilter) {
              content {
                contact {
                  firstName
                  lastName
                  fullName
                }
                id
              }
            }
          }
        `,
                variables: {
                    patientFilter: {search: payload?.search ?? ''}
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                    },
                },
            });
            commit('setAllPatient', response.data.findAllPatient);
        } catch (error) {
            console.error('Failed to fetch Patients:', error.message);
        }
    },

    async patientArriveStatus({commit, dispatch}, {patient,start,end,listOfUserId, departmentId}) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation updateAttendeesArriveStatus($id: Int!, $patientArriveStatus: PatientArriveStatus!) {
            updateAttendeesArriveStatus(id: $id, patientArriveStatus: $patientArriveStatus) {
              patientArriveStatus
            }
          }
        `,
                variables: {id: patient.id, patientArriveStatus: patient.patientArriveStatus},
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                    },
                },
            });

            dispatch('fetchAllBookings',{start,end,listOfUserId, departmentId});

            // console.log(patient, "patient - patientArriveStatus");

        } catch (error) {
            console.error('Failed to update patient status:', error.message);
        }

    },

    async cancelBooking({commit, dispatch}, {patientId, reason, notes, bookingId}) {
        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation CancelAttendee($id: Int!, $cancelAttendeeInput: CancelAttendeeInput!) {
            cancelAttendee(id: $id, cancelAttendeeInput: $cancelAttendeeInput) {
              cancellationNote
              cancellationReason
            }
          }
        `,
                variables: {
                    id: patientId,
                    cancelAttendeeInput: {
                        cancellationReason: reason,
                        cancellationReasonNote: notes
                    }
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + (localStorage.getItem('accessToken') || ''),
                    },
                },
            });

            dispatch('fetchSingleBooking', bookingId);

            console.log(patientId, reason, notes, bookingId);
        } catch (error) {
            console.error('Failed to cancel booking:', error.message);
        }
    },

    async updateAttendeeNotes({commit, dispatch}, {booking, patients,start,end,listOfUserId, departmentId}) {

        try {
            const response = await apolloClient.mutate({
                mutation: gql`
          mutation updateBooking($departmentId: Int!, $id: Int!, $endTime: String!, $startTime: String!, $bookingDate: String!, $userId: Int!, $serviceId: Int!, $maxPatient: Int!, $patientsId: [BookingPatientInput]!, $repeatBy: RepeatedByEnum!, $endAfter: Int!, $repeatEvery: Int!) {
            updateBooking(
              id: $id
              bookingInput: {
                departmentId: $departmentId, 
                listOfPatients: $patientsId,
                endTime: $endTime,
                maxPatient: $maxPatient,
                appointmentTypeServiceId: $serviceId,
                userId: $userId,
                bookingDate: $bookingDate,
                startTime: $startTime,
                repeatBy: $repeatBy,
                repeatEvery: $repeatEvery,
                endAfter: $endAfter,
              }
            ) {
              appointmentTypeService {
                durationInMint
                name
                id
              }
              bookingDate
              maxPatient
              endTime
              id
              startTime
              repeatBy
              endAfter
              repeatEvery
              userId
              user {
                contact {
                  firstName
                  lastName
                }
              }
              attendees {
                notes
                id
                cancellationNote
                cancellationReason
                patientId
                patient {
                  contact {
                    firstName
                    lastName
                  }
                }
                patientArriveStatus
              }
            }
          }
        `,
                variables: {
                    id: booking.id,
                    endTime: booking.endTime,
                    startTime: booking.startTime,
                    bookingDate: booking.bookingDate,
                    userId: booking.userId,
                    serviceId: booking.appointmentTypeService.id,
                    maxPatient: booking.maxPatient,
                    repeatBy: booking.repeatBy,
                    endAfter: booking.endAfter,
                    repeatEvery: booking.repeatEvery,
                    patientsId: patients,
                    departmentId: departmentId
                },
                context: {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                    },
                },
            });
            // console.log(response.data.updateBooking);
            commit('setBooking', response.data.updateBooking);
            dispatch('fetchAllBookings',{start,end,listOfUserId, departmentId});

            // console.log(patients, "patients");
            // console.log(booking, "booking");

        } catch (error) {
            console.error('Failed to add patients:', error.message);
        }

    },

    async fetchDepartments({commit}) {
      try {
          const response = await apolloClient.query({
              query: gql`
              query findAllDepartments {
                findAllDepartments {
                  id
                  name
                }
              }
            `,
           
              context: {
                  headers: {
                      Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                  },
              },
          });
          commit('setDepartments', response.data.findAllDepartments);
      } catch (error) {
          console.error('Failed to fetch Departments:', error.message);
      }
  },


};

export default {
    state,
    getters,
    mutations,
    actions,
};
