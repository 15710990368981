import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import apolloProvider from './plugins/vue-apollo';
import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';
import Notifications from 'vue-notification'
Vue.config.productionTip = false
const ability = new Ability([]);
Vue.use(abilitiesPlugin, ability);
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.use(Notifications)



const i18n=new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    ar: require('./locales/ar.json'),
    en: require('./locales/en.json')
  }
})
new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider,
  render: function (h) { return h(App) },
  data: {
    ability
  }
}).$mount('#app')
