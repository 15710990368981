import gql from 'graphql-tag';
import apolloClient from '../../apollo/apollo';

const state = {
    locales: ['ar', 'en'],
    locale: 'ar',
    language: {
        locale: 'ar',
        rtl: 'rtl',
        direction: 'right',
    },
    direction: true,
    messages: 'success',
    loading: false,
    drawer: null,
    miniDrawer: false,
};

const getters = {

    getLocal(state) {
        return state.locale;
    },
    getLanguage(state) {
        return state.language;
    },
    getDirection(state) {
        return state.direction;
    },
    getMessages(state) {
        return state.messages;
    },
    getLoading(state) {
        return state.loading;
    },
    getDrawer(state) {
        return state.drawer;
    },
    getMiniDrawer(state) {
        return state.miniDrawer;
    },


};

const mutations = {
    setDrawer: (state,property) => {
        state.drawer = property
    },
    setMiniDrawer: (state,property) => {
        state.miniDrawer = !property
    },

    SET_LANG(state, locale) {
        if (state.locales.includes(locale.locale)) {
            state.locale = locale.locale
            state.language = locale
        }
    },
    projectDirection: (state, lang) => {
        if (lang === 'ar') {
            state.direction = true
        }
        if (lang !== 'ar') {
            state.direction = false
        }
    },
    successMessage: (state, data) => {
        Vue.notify({
            duration: 6000,
            speed: 400,
            type: 'success',
            group: 'successmesages',
            closeOnClick: true,
            text: 'apisuccessmesages.title' ,
            // title: 'apisuccessmesages.title' + data,
        })

        state.messages = 'success'
    },
    failMessage: (state, data) => {
        Vue.notify({
            duration: 6000,
            speed: 400,
            type: 'error',
            group: 'failmessages',
            text: 'apifailmessages.apifail' ,
            title: 'apifailmessages.title',
        })

        state.messages = 'error'
    },
    loadingFinish: (state) => {
        // console.log('dsf')
        state.loading = false
    },
    toggleDrawer: (state) => {
        state.drawer = !state.drawer
    },
    loadingStart: (state) => {
        // console.log('dsf')
        state.loading = true
    },

};

const actions = {
    setDrawerAction({commit}, property) {
        commit('setDrawer', property)
    },
    toggleDrawerAction({commit}, property) {
        commit('toggleDrawer', property)
    },
    setMiniDrawerAction({commit}, property) {
        commit('setMiniDrawer', property)
    },
}

export default {
    state,
    getters,
    mutations,
    actions
};
