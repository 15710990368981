import apolloClient from "@/apollo/apollo";
import gql from "graphql-tag";

const state = {
    countries: []
};


const getters = {

};

const mutations = {

};

const actions = {








};

export default {
    state,
    getters,
    mutations,
    actions,
};
